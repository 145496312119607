import { Injectable } from '@angular/core';
import { diccionario } from '../providers/diccionario';

interface DiccionarioItem {
  "name": string,
  "velocidadMB": string,
  "velocidadKbps": string,
  "tipo": string
}

@Injectable({
  providedIn: 'root'
})

export class DiccionarioService {
  private diccionario: DiccionarioItem[] = diccionario;
  constructor() { 

  }

  reemplazarServicio(servicio: string) {
    const names: string[] = this.diccionario.map((item: DiccionarioItem) => item.name);
    const index = names.indexOf(servicio.trim());
    if (index>=0) {
      return this.diccionario[index].velocidadMB;
    }
    return servicio;
  }
}
