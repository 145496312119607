import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class LogErrorsService {
  private _errors: string[] = [];
  constructor(
    private _http: HttpClient,
  ) {
    
  }

  async insertarError(message: any) {
    
    if (typeof message === "string") {
      if (!!this._errors.length && (this._errors[this._errors.length - 1] === message )) {
        return;
      }
      this._errors.push(message)
      let date: any;
      date = new Date();
      date = date.getUTCFullYear() + '-' +
      ('00' + (date.getUTCMonth()+1)).slice(-2) + '-' +
      ('00' + date.getUTCDate()).slice(-2) + ' ' + 
      ('00' + date.getUTCHours()).slice(-2) + ':' + 
      ('00' + date.getUTCMinutes()).slice(-2) + ':' + 
      ('00' + date.getUTCSeconds()).slice(-2);
  
      const user = JSON.parse(localStorage.getItem("user")) || null;
      const body =  {
        cedula: user.identidad || 'No hay usuario',
        fecha: date,
        errorMessage: message,
      } 
       
      try {
        const headers = {
          'x-keys-to-add-id': '["id"]',
          'x-keys-of-arrays': '[]',
          'x-relations': 'false',
        };
        await this._http.post(`${environment.apiDbMaps}create-info/tmLogs`, body, { headers }).toPromise();
      } catch (error) {
        console.log(error)
      }
    }
  }
}
