import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment.prod';
import { LogErrorsService } from './log-errors.service';

@Injectable({
  providedIn: 'root'
})
export class MiEquipoService {
  public speedData : any = [];
  constructor(
    private _http:HttpClient,
    private _logs: LogErrorsService,
  ) { 

  }

  async getImage(name: string) {
    try {
      const response:any = await this._http.get(`${environment.apiDbMaps}find-any-info/olt_onu_type/name/${name}`).toPromise();
      return {
        data: response,
        error: false,
      }
    
    } catch (error) {
      this._logs.insertarError(error.message);
      return { 
        error: true, 
        data: []
      }
    }
  }

  async getSpeedData() {
    try {
      const responseRow:any = await this._http.get(`${environment.apiOltFirst}get_speed_profiles`).toPromise();
      const {
        response,
      } = JSON.parse(responseRow[0]);
      this.speedData = response;
    } catch (error) {
      this._logs.insertarError(error.message);
      this.speedData = []
    }
  }

  async getSpeed(name: string) {
    try {
      if (this.speedData.length === 0) {
        await this.getSpeedData();
      }
      const dataObj = this.speedData.find((item:any) => item.name == name);
      const speed = this.transformKbToMb(dataObj.speed);
      return { 
        error: false, 
        data: speed,
      }
    } catch (error) {
      return { 
        error: true, 
        data: null
      }
    }
  }


  transformKbToMb(speed: number) {
    if (speed < 1024) {
      return `${speed}mb`;
    }
    const transformed = Math.round(speed/1024);
    return `${transformed}mb`;
  }

  async getInfo(parameters: string) { 
    try {
      const response:any = await this._http.get(`${environment.apiOlt}${parameters}`).toPromise();
      return JSON.parse(response[0])
    
    } catch (error) {
      this._logs.insertarError(error.message);
      throw new Error("algo salio mal");  
    } 
  }
  async getAllInfo(deviceId: string) {
    const parameters:string[] = [
      //De aqui sacas la señal:
      `get_onu_signal/${deviceId}`,
      //de aqui sacas la cuenta:
      `get_onu_administrative_status/${deviceId}`,
      //Plan sácalo de aquí:
      `get_onu_speed_profiles/${deviceId}`,
      `get_onu_status/${deviceId}`
    ]
    try {
      const responses = await Promise.all(parameters.map((params: string) => this.getInfo(params)));
      return responses;
    } catch (error) {
      this._logs.insertarError(error.message);
      return { 
        error: true, 
        data: []
     }
    }
  }

}
/*

https://api.thomas-talk.me/olt2/get_onu_signal/HWTCFDF598A9

de aqui sacas la cuenta:

https://api.thomas-talk.me/olt2/get_onu_administrative_status/HWTCFDF598A9

de aquí sacas las grafica 

https://crm.thomas-talk.me/appgraph/?onu=ZTEGC56E2C82&tipo=monthly
Esto es el SRC de la imagen te pase un html para que entiendas.

Plan sácalo de aquí:
https://api.thomas-talk.me/olt2/get_onu_speed_profiles/HWTCFDF598A9 


*/
