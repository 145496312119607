import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PropagandaPage } from './pages/modal/propaganda/propaganda.page';
import { Platform, NavController, MenuController, ModalController, AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { TranslateProvider } from './providers/translate/translate.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { UserAuthenticationService } from './services/user-authentication.service';
import { GetInfoDeviceService } from './services/get-info-device.service';
import { ConsultasService } from './services/consultas.service';
import { Pages } from './interfaces/pages';
import { SubscriptionGraphqlService } from "./services/subscription-graphql.service";
import { OptionMenu } from './providers';
import { ShowSaldoService } from './services/show-saldo.service';
import gql from 'graphql-tag';
import { GuiaProgramacionService } from './services/guia-programacion.service';
import { LocalStorageService } from './services/local-storage.service';
import { url } from 'inspector';
import { NewUpdatePage } from './pages/new-update/new-update.page';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { EstadisticasService } from './services/estadisticas.service';
import { LogErrorsService } from './services/log-errors.service';
/**
 * Main Wrap App Component with starting methods
 *
 * @export
 * @class AppComponent
 */
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  VersionApp = environment.version
  ShowMenu: Boolean = true;
  /**
   * Creates an Array instance with <Pages> interface that receives all menu list.
   *
   * @type {Array<Pages>}
   * @memberof AppComponent
   */
  public appPages: Array<Pages>;
  public user = null;
  private propaganda : boolean = false;
  private isAlertOpen : boolean = false;
  /**
   * Creates an instance of AppComponent.
   * @param {Platform} platform
   * @param {SplashScreen} splashScreen
   * @param {StatusBar} statusBar
   * @param {TranslateProvider} translate
   * @param {TranslateService} translateService
   * @param {NavController} navCtrl
   * @memberof AppComponent
   */

  private idleState: string = "NOT_STARTED";
  private countdown?: number = null;
  private lastPing?: Date = null;
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateProvider,
    private translateService: TranslateService,
    public navCtrl: NavController,
    private authentication: UserAuthenticationService,
    public menu: MenuController,
    private getInfo: GetInfoDeviceService,
    private consultService: ConsultasService,
    private showSaldoService: ShowSaldoService,
    private guia: GuiaProgramacionService,
    private _SubscriptionGraphqlService: SubscriptionGraphqlService,
    private _localStorageService: LocalStorageService,
    public modalCtrl: ModalController,
    private idle: Idle,
    private cd: ChangeDetectorRef,
    private alert: AlertController,
    private estadisticas: EstadisticasService,
    private _logs: LogErrorsService,
  ) {
    this.estadisticas.crearAcciones();
    const user = this.authentication.getUser();
    // let posFibexPlay = OptionMenu.findIndex((DMenu: any) => DMenu.title === "Fibex Play")
    // OptionMenu[posFibexPlay].show = user ? user.fibex_play : true;
    this.appPages = OptionMenu;
    this.initializeApp();
    this.getUserData();
    /* this.getInfo.getIpFromUser()
      .subscribe(res => this._localStorageService.set('ipUser', res)); */
    this.guia.loadGuia();
    if (environment.kioskoApp) {
      this.initTimer();
    }

  }
  /**
   * Method that starts all Cordova and Factories
   *
   * @memberof AppComponent
   */
  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      setTimeout(() => {
        this.splashScreen.hide();
      }, 1000);
      // Set language of the app.
      this.translateService.setDefaultLang(environment.language);
      this.translateService.use(environment.language);
      this.translateService.getTranslation(environment.language).subscribe(translations => {
        this.translate.setTranslations(translations);
      });
    }).catch((error:any) => {
      // Set language of the app.
      this.translateService.setDefaultLang(environment.language);
      this.translateService.use(environment.language);
      this.translateService.getTranslation(environment.language).subscribe(translations => {
        this.translate.setTranslations(translations);
      });
      this._logs.insertarError(error.message)
    });
  }
  /**
   * Navigate to Edit Profile Page
   *
   * @memberof AppComponent
   */

  goToEditProgile() {
    this.navCtrl.navigateForward('edit-profile');
    this.closeMenu();
  }
  /**
   * Logout Method
   *
   * @memberof AppComponent
   */

  ngOnInit(): void {
    if (environment.kioskoApp) {
      this.reset();
    }

    if (this.user) {

      this.consultService.ServerDisponibles(this.user.identidad).then((ResServer: any) => {
        if (Object.keys(ResServer).length > 0) {

          environment.ApiGraphQl = ResServer.ApiGraphQl
          environment.UrlSubscriptions = ResServer.UrlSubscriptions
          environment.apiChatBot = ResServer.apiChatBot
          environment.apiMaps = ResServer.apiMaps
          environment.apiPhP = ResServer.apiPhP
          environment.ApiImgBot = ResServer.ApiImgBot

          if (ResServer.OnSuscription) {
            this._SubscriptionGraphqlService.initSubscription()
          }

        }
      }).catch((error:any) => {
        this._logs.insertarError(error.message);
      })

      this.consultService.VersionControl(this.user.identidad, this.VersionApp).then((res: any) => {
        if (res.url) {
          this.ShowMenu = false
          this.navCtrl.navigateForward('NewUpdate');
        }
      })
    }

    this._SubscriptionGraphqlService.OpSelect.subscribe((op: any) => {
      switch (op.option) {
        case "op12-1":
          this.menu.open()
          break;
        case "op12-2":
          this.menu.close()
          break;

      }

    })

  }

  async closeMenu() {
    if (await this.menu.isOpen()) {
      await this.menu.close();
      await this.menu.enable(false);
    }
  }

  getUserData() {
    this.user = this.authentication.getUser();
    this.authentication.userState.subscribe((userData: any) => this.user = userData);
  }

  logout() {
    this.authentication.logout();
    this.navCtrl.navigateRoot('login');
    if (environment.kioskoApp) {
      this._localStorageService.removeAll();
    }

  }

  showSaldo(url: string) {
    this.showSaldoService.setMenu(url);
  }

  initTimer() {
    const user = this.authentication.getUser();
    if (user) {
      this.idle.setIdle(150); // how long can they be inactive before considered idle, in seconds
      this.idle.setTimeout(30); // how long can they be idle before considered timed out, in seconds
    } else {
      this.idle.setIdle(10);
      this.idle.setTimeout(300);
    }
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES); // provide sources that will "interrupt" aka provide events indicating the user is active

    // do something when the user becomes idle
    this.idle.onIdleStart.subscribe(() => {
      this.idleState = "IDLE";
      const user = this.authentication.getUser();
      if (user) {
        this.createAlert("La sección finalizará en: 30s");
        this.isAlertOpen = true;
      } else {
        this.openPropaganda();
        this.propaganda = true;
      }
      
    });
    // do something when the user is no longer idle
    this.idle.onIdleEnd.subscribe(() => {
      const user = this.authentication.getUser();
      if (user) {
        if (this.isAlertOpen) {
          this.isAlertOpen = false;
          this.alert.dismiss();
        }
        this.idle.setIdle(150); // how long can they be inactive before considered idle, in seconds
        this.idle.setTimeout(30);
      } else {
        if (this.propaganda) {
          this.modalCtrl.dismiss();
          this.propaganda = false;
          
        }
        this.idle.setIdle(10); // how long can they be inactive before considered idle, in seconds
        this.idle.setTimeout(300);
      }
      
      this.idleState = "NOT_IDLE";
      this.countdown = null;
      this.cd.detectChanges(); // how do i avoid this kludge?
       
    });
    // do something when the user has timed out
    this.idle.onTimeout.subscribe(() => {
      const user = this.authentication.getUser();
      if (user) {
        if (this.isAlertOpen) {
          this.alert.dismiss();
          this.isAlertOpen = false;
          this.navCtrl.navigateForward("/login")
          this._localStorageService.removeAll();
        }

        this.idle.setIdle(150);
        this.idle.setTimeout(30); 
      } else {
        if (this.propaganda) {
          this.propaganda = false;
          this.modalCtrl.dismiss();
        }
        this.idle.setIdle(10);
        this.idle.setTimeout(300); 
      }
      this.idleState = "TIMED_OUT"
      this.reset();
    });
    // do something as the timeout countdown does its thing
    this.idle.onTimeoutWarning.subscribe(seconds => {
      this.countdown = seconds
    });

    // set keepalive parameters, omit if not using keepalive
    //this.keepalive.interval(15); // will ping at this interval while not idle, in seconds
    //this.keepalive.onPing.subscribe(() => this.lastPing = new Date()); 
  }


  reset() {
    // we'll call this method when we want to start/reset the idle process
    // reset any component state and be sure to call idle.watch()
    this.idle.watch();
    this.idleState = "NOT_IDLE";
    this.countdown = null;
    this.lastPing = null;
  }

  async createAlert(header: string) {
    const alert = await this.alert.create({
      header,
      buttons: [
        {
          text: 'Ok',
          role: 'Cancelar',
          cssClass: 'secondary',
        }
      ]
    });
    alert.present()
  }


  async openPropaganda() {
    const modal = await this.modalCtrl.create({
      component: PropagandaPage,
    });
    await modal.present();
  }

}
