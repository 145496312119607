



import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, NavController } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { ConsultasService } from '../../../services/consultas.service';
import { UserAuthenticationService } from '../../../services/user-authentication.service';
import { DateCompareService } from '../../../utilities/date-compare.service';
import { UpgrateServicePage } from '../upgrate-service/upgrate-service.page';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { SubscriptionGraphqlService } from "../../../services/subscription-graphql.service";
import { LocalStorageService } from '../../../services/local-storage.service';
import { DiccionarioService } from '../../../services/diccionario.service';
import { EstadisticasService } from '../../../services/estadisticas.service';
import { MiEquipoService } from "../../../services/mi-equipo.service";
import { VerifyInternetStatusService } from '../../../services/verify-internet-status.service';

@Component({
  selector: 'app-show-services',
  templateUrl: './show-services.page.html',
  styleUrls: ['./show-services.page.scss'],
})
export class ShowServicesPage implements OnInit {

  @Input() dniClient: string = '';
  @Input() useModal: boolean = false;
  @Input() mesualidad?: string;
  public infoEquipo: any[] = []
  public service: any;
  public formService: FormGroup;
  public dateTurbo: any;
  public countTurbos: any;
  public user: any;
  public http: HttpClient;
  public infoPaquetes: any;
  public infoPaquetesGreater: any = [];
  public numberPattern: RegExp = new RegExp(/\d+/g);
  BtnDisable: boolean = false
  OrdenPendiente: boolean = false
  public onLine: any

  DataCeduAct: any;

  ClienteActual: any

  AllServices: any = []

  constructor(
    private modal: ModalController,
    private fb: FormBuilder,
    private consultServices: ConsultasService,
    private dateCompare: DateCompareService,
    private alertController: AlertController,
    private userAuth: UserAuthenticationService,
    public modalCtrl: ModalController,
    public _navCtrl: NavController,
    private alert: AlertController,
    private _SubscriptionGraphqlService: SubscriptionGraphqlService,
    private _localStorageService: LocalStorageService,
    public diccionario: DiccionarioService,
    private _estadisticas: EstadisticasService,
    private _miEquipo: MiEquipoService,
    private _internetService: VerifyInternetStatusService,
  ) {

    this.DataCeduAct = this._localStorageService.get('CedulaActual');
    this.dateTurbo = this._localStorageService.get('dateTurbo');
    this.countTurbos = this._localStorageService.get('countTurbos');

    this.VerifyOrdenes()

    this._estadisticas.registrar(4);
    this._internetService.testNetworkConnection()
    this.onLine = this._internetService.onLine;
    let emited = false;
    this._internetService.internetState.subscribe((data: boolean) => {
      if (!emited) {
        this.onLine = data;
        if (!this.onLine) {
          this._internetService.verifyConection(this.onLine, true);
          this._navCtrl.navigateForward('home');
        }
      }
      emited = true;
    });
  }

  ngOnInit() {

    this.ListServiceTo();

    this._SubscriptionGraphqlService.OpSelect.subscribe((op: any) => {
      switch (op.option) {
        case "op5-1":
          this.closeModal();
          break;
        case "op5-2":
          this.ModalUpgrade(this.infoPaquetesGreater[0])
          break;

      }

    })

    this.formService = this.fb.group({
      serviceSelect: ['']
    });

    this.user = this.userAuth.getUser();

    if (this.DataCeduAct) {
      this.mesualidad = this.DataCeduAct.Mensualidad
      this.getInfoEquipoClient(this.DataCeduAct.identidad)
      // this.GetAllService(this.DataCeduAct.identidad)
      return;
    } else {
      if (this.dniClient.length > 0) {
        this.getInfoEquipoClient(this.dniClient);
        // this.GetAllService(this.dniClient)
        return;
      } else if (this.dniClient.length === 0) {
        this.getInfoEquipoClient(this.user.identidad);
        // this.GetAllService(this.user.identidad);
        return;
      }
    }

  }

  get serviceSelect() { return this.formService.get('serviceSelect'); }

  VerifyOrdenes() {
    try {

      this.consultServices.GetHistorialOrdenes(this.DataCeduAct.id_contrato).then((ResOrdenes: any) => {
        let DateTurboUP = new Date(this._localStorageService.get('SolicitudUpTur'))
        let DateAct = new Date()

        let Encontre: boolean = false, i = 0

        while (i < ResOrdenes.length && Encontre == false) {
          if (ResOrdenes[i].nombre_tipo_orden === 'UPGRADE SERVICIO POR APP FIBEX') {

            if (ResOrdenes[i].status_orden != "FINALIZADA") {
              this.OrdenPendiente = true //orden pendiente por finalizar
            } else if ((ResOrdenes[i].status_orden === "FINALIZADA")) {
              this.OrdenPendiente = false // sin ordenes pendientes 
            }

            Encontre = true
          }
          i++;
        }

        if ((`${DateAct.getDate()}/${(DateAct.getMonth() + 1)}` === `${DateTurboUP.getDate()}/${(DateTurboUP.getMonth() + 1)}`) || (this.OrdenPendiente === true)) {
          this.BtnDisable = true
        } else { this.BtnDisable = false }

      })

    } catch (error) {
      console.error(error)
    }

  }

  /* GetAllService(Cedula: any) {
    try {

      this.consultServices.GetAllServiceCliente(Cedula).then((ResService: any) => {
        console.log('Data servicios')
        console.log(ResService)
        this.AllServices = ResService

        if (this.infoEquipo.length <= 0 && !this.service) {
          this.service = {
            codigo_es: ResService[0].id_serv,
            id_es: ResService[0].id_cont_serv,
            nombre_modelo: "MODELO DEFAULT",
            paquetes: ResService[0].nombre_servicio,
            status_es: ResService[0].status_con_ser,
          }
        }

      }).catch(err => console.error(err))

    } catch (error) {
      console.error(error)
    }
  } */

  getInfoEquipoClient(dni: string) {
    this.consultServices.InfoEquiposClientes(dni)
      .then(async (res) => {
        try {
          this.infoEquipo = res;
          if (res.length > 0) {
            this.service = this.infoEquipo[0];

            const speedResponse = await this._miEquipo.getSpeed(this.service.paquetes.replace(";", "").trim());
            const speedData = speedResponse.data;

            if (speedData) {
              this.service.paquetes = speedData;
            } else {
              this.service.paquetes = this.service.paquetes;
            }
            this.infoPaquetesGreater = this.filterService(this.service.paquetes)

            this.serviceSelect.patchValue(this.service.codigo_es);
            return;
          } 
          /* else {

            this.AllServices.forEach((element: any) => {
              this.infoEquipo.push({
                codigo_es: element.id_serv,
                id_es: element.id_cont_serv,
                nombre_modelo: "MODELO DEFAULT",
                paquetes: element.nombre_servicio,
                status_es: element.status_con_ser,
              })
            });

          } */
          if (this.useModal) {
            this.closeModal();
          } else {
            this._navCtrl.back();
          }
          this.createAlert();
        } catch (error) {

        }

      })
  }

  filterService(service: string) {


    let result = service.match(this.numberPattern)

    if (result) {
      let currentCapacity = result.join('');
      let arrayFilterservice = this.infoPaquetes.filter((paquete) => {
        let capacidad = paquete.Capacidad.match(this.numberPattern)
        if (parseInt(currentCapacity) < parseInt(capacidad)) {
          if (this.user.tipoCliente === 'Persona Natural') {
            if (paquete.Categoria === 'HOGAR') {
              return paquete;
            }

          } else if (paquete.Categoria !== 'HOGAR') {
            return paquete;
          }
        }
      })
      return arrayFilterservice;
    }
    return [];
  }

  async ListServiceTo() {
    /* let infopack = await this.consultServices.ListService()
    if (infopack) {
      this.infoPaquetes = infopack;
    } */

    this.consultServices.ListService().then((ResService: any) => {
      this.infoPaquetes = ResService
    })

    /**
     * 
     * json turbo upgrade
  
  {
          Cedula:"12345678",
          idDevice:"hkj58we",
          Accion:"UPGRATE", indica que selecciono el cliente un UPGRATE o un TURBO
          OldPlan:"10Mb-Hogar", plan actual 
          NewPlan:"30Mb-Hogar", plan nuevo
          Status:0, 
          Email:"juanlobo@outlook.com"
        }
  
  json tvpermiso
  
  {
            Cedula: "29845112",
            idDevice: "6464",
            Canal: "Prueba"
          }
     */
  }


  async servicesSelected(codigo_es: string) {
    try {
      let services = this.infoEquipo.find((service) => service.codigo_es === codigo_es);
      if (services) {
        this.service = services;
        const speedResponse = await this._miEquipo.getSpeed(this.service.paquetes.replace(";", "").trim());
        const speedData = speedResponse.data;
        if (speedData) {
          this.service.paquetes = speedData;
        } else {
          this.service.paquetes = this.service.paquetes;
        }
        this.infoPaquetesGreater = this.filterService(this.service.paquetes)

        this.serviceSelect.patchValue(this.service.codigo_es);

      }
    } catch (error) {

    }

  }

  addTurbo() {


    const DatosServicios = {
      PlanActual: this.service.paquetes,
      Accion: 'TURBO',
      ...this.infoPaquetesGreater[0],
      ...this.DataCeduAct,
      ...this.service
    }

    this.dateTurbo = this._localStorageService.get('dateTurbo');
    this.countTurbos = this._localStorageService.get('countTurbos');
    let countTurbosInit: number;
    let date: any

    if (this.dateTurbo === null) {
      countTurbosInit = 0;
      date = parseInt(this.dateTurbo);
      // return;
    } else if (this.countTurbos) {
      countTurbosInit = this._localStorageService.get('countTurbos');
      // return;
    }
    if (date !== undefined && !isNaN(date)) {

      // se verifica que sean 24hrs despues de la ultima que se guardo
      // si es asi se aplica un nuevo turbo
      // si no es asi el cliente no puede optar por uno nuevo
      const { hours, minutes, seconds, days } = this.dateCompare.timeDifference(new Date().getTime(), date);

      if (hours >= 24) {

        if (countTurbosInit === 3) {
          if (new Date().getDate() === 1) {
            // Es el primer dia del mes
            countTurbosInit = 0;
            return;
          } else {
            // ya alcanzo el maximo de turbos por mes

            this.presentAlertMultipleButtons('Turbo', 'Lo sentimos, ya alcanzo el máximo de turbos por mes').then();
            return
          }
        }
        // se aplica un nuevo turbo
        // Consulta API y guarda información 
        if (this.infoPaquetesGreater.length > 0) {
          this.consultServices.TurBoUpgrade(DatosServicios).then((res) => {

            let newDate = new Date().getTime();
            this._localStorageService.set('countTurbos', ++countTurbosInit);
            this._localStorageService.set('dateTurbo', newDate);

            this.presentAlertMultipleButtons('Turbo', 'Turbo aplicado con éxito, dipones del doble de tu velocidad por 24h.').then();
            this.BtnDisable = true
            this._localStorageService.set('SolicitudUpTur', new Date())
          })
          return;
        }

      }
      // Mensaje indicando que aun no han pasado las 24hrs
      this.presentAlertMultipleButtons('Turbo', 'Lo sentimos, no ha pasado el tiempo necesario (24h) para aplicar un nuevo turbo, debe esperar').then();
      return;

    } else {

      // se aplica turbo la primera vez o no teniedo fecha inicial del primer turbo


      if (this.infoPaquetesGreater.length > 0) {
        this.consultServices.TurBoUpgrade(DatosServicios).then((res) => {

          let firstDate = new Date().getTime();
          this._localStorageService.set('countTurbos', ++countTurbosInit);
          this._localStorageService.set('dateTurbo', firstDate);
          this.BtnDisable = true
          this._localStorageService.set('SolicitudUpTur', new Date())

          this.presentAlertMultipleButtons('Turbo', 'Turbo aplicado con éxito').then();
        })
      }

    }


  }

  async createAlert() {
    const alert = await this.alert.create({
      header: 'No tiene servicios disponibles.',

      buttons: [
        {
          text: 'Ok',
          role: 'Cancelar',
          cssClass: 'secondary',
        },
      ]
    });
    alert.present();
  }

  closeModal() {
    this.modal.dismiss();
  }

  async presentAlertMultipleButtons(header: string, msg: string) {
    const alert = await this.alertController.create({
      header: header,
      message: msg,
      buttons: ['Aceptar']
    });

    await alert.present();
  }

  async ModalUpgrade(pack: any) {

    const modal = await this.modalCtrl.create({
      component: UpgrateServicePage,
      componentProps: {
        PlanActual: this.service.paquetes,
        DatosUser: this.DataCeduAct,
        DatosEquipo: this.service,
        pack
      }
    });
    await modal.present();

    const DataReturn = await modal.onWillDismiss();
    this.BtnDisable = DataReturn.data
  }

}
