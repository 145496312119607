import { Injectable, EventEmitter } from '@angular/core';
import { UserInfo } from '../interfaces/userInfo';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment.prod';
import { LocalStorageService } from './local-storage.service';
import { LogErrorsService } from './log-errors.service';

@Injectable({
  providedIn: 'root'
})

export class UserAuthenticationService {
  public userState: EventEmitter<any> = new EventEmitter<any>()
  constructor(
    public http: HttpClient,
    private _localStorageService: LocalStorageService,
    private _logs: LogErrorsService, 
  ) { }

  public getUser() {
    return this._localStorageService.get('user') || null;
  }

  public registerUser(userInfo: UserInfo) {
    this._localStorageService.removeAll();
    this._createAccount(userInfo);
    this.login({ email: userInfo.email, password: userInfo.password })
    this.userState.emit({ ...userInfo, municipio: "", estado: "", parroquia: "" });
    return true;
  }

  private _createAccount(userInfo: UserInfo) {
    this._localStorageService.set('user', { ...userInfo, municipio: "", estado: "", parroquia: "" });
  }

  public noRegisterdUser() {
    const users = this._localStorageService.get('user');
    if (!users) {
      return true;
    }
    return false;
  }

  public login(userInfo: { email: string, password: string }) {
    const registeredUser = this._localStorageService.get('user');
    if (!registeredUser) {
      return false;
    }
    const validUser = (userInfo.email.toUpperCase() === registeredUser.email.toUpperCase() && userInfo.password === registeredUser.password) || (userInfo.email.toUpperCase() === registeredUser.email.toUpperCase() && userInfo.password.replace(/[^\d]/gi, '') === registeredUser.password);
    if (!validUser) {
      return false;
    }

    this._localStorageService.set('auth-user', userInfo);
    return true;
  }

  modifyUser(userInfo: UserInfo) {
    const user = this._localStorageService.get('user');
    let userInfoUpdate = { ...user, ...userInfo };
    this._localStorageService.set('user', userInfoUpdate);
    this.userState.emit(userInfoUpdate);
  }

  public logout() {
    this._localStorageService.remove('auth-user');
  }

  public async loginCRM(email: string, password: string) {
    //http://localhost/phpass.php?email=HADADJORGE1@GMAIL.COM&pass=jermsof
    try {
      const responseCliente: any = await this.http.get(`${environment.apiPhP}email=${email}&pass=${password}`).toPromise();
      if (!responseCliente) { 
        return { status: false };
      }

      this._localStorageService.set('HaveFibexP', responseCliente.fibex_play)
      
      return responseCliente;

    } catch (error) {
      this._logs.insertarError(error.message);
      return { status: false };
    }

  }

  public async resetPassword(email: string, password: string) {
    try {
      const response: any = await this.http.get(`${environment.apiResetPassword}email=${email}&pass=${password}`).toPromise();
      return response;
    } catch (error) {
      this._logs.insertarError(error.message);
      return { 
        status: false,
        email: null,
        password: null,
      }
    }
  }

  public async verifyCedula(cedula: string) {
    try {
      const response: any = await this.http.get(`${environment.apiVerifyCedula}cedula=${cedula}`).toPromise();
      return response;
    } catch (error) {
      this._logs.insertarError(error.message);
      return { 
        status: false,
        cedula: null,
      };
    }
  }

}