import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { LocalStorageService } from '../../../services/local-storage.service';

@Component({
  selector: 'app-pin-registration',
  templateUrl: './pin-registration.page.html',
  styleUrls: ['./pin-registration.page.scss'],
})
export class PinRegistrationPage implements OnInit {
  public registerPinForm: FormGroup;

  constructor(
    private modalCtrl: ModalController,
    private _localStorageService: LocalStorageService,
    private formBuilder: FormBuilder,
  ) { 
  }

  ngOnInit() {
    this.modalCtrl.dismiss();
    this.registerPinForm = this.formBuilder.group({
      'pinRegister': [null, Validators.compose([
        Validators.required
      ])],
    });
  }
  
  savePin() {
    this._localStorageService.set('pinRegister', this.registerPinForm.value.pinRegister)
    this.closeModal();
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}