import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AlertController, NavController } from '@ionic/angular';
import { TranslateProvider, HotelProvider } from '../../providers';
import { UserAuthenticationService } from '../../services/user-authentication.service';
import { SubscriptionGraphqlService } from "../../services/subscription-graphql.service";
import gql from "graphql-tag";
import { LocalStorageService } from '../../services/local-storage.service';
import { VerifyInternetStatusService } from '../../services/verify-internet-status.service';
import { EstadisticasService } from '../../services/estadisticas.service';
import { environment } from '../../../environments/environment.prod';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConsultaPagoMovilService } from '../../services/consulta-pago-movil.service';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.page.html',
  styleUrls: ['./favorites.page.scss'],
})

export class FavoritesPage implements OnInit {
  public onPagoMovilForm: FormGroup;
  public onLine: boolean = true;
  public CedulaActual: any;
  public urlPagos: string = 'https://pagos.fibextelecom.net/pay?dni=';
  public secureUrl: string = '';
  public permitirPagoMovil = environment.permitirPagoMovil;
  public transferencia = false;
  public pagoMovil = false;
  public pin: string = null;
  public pagado = false;
  public success = false;
  public failure = false;
  public fecha = "";
  public data = null;
  public bancos : any[] = [];
  constructor(
    public navCtrl: NavController,
    private _SubscriptionGraphqlService: SubscriptionGraphqlService,
    private _localStorageService: LocalStorageService,
    private _internetService: VerifyInternetStatusService,
    private _estadisticas: EstadisticasService,
    private _formBuilder: FormBuilder,
    private _alert: AlertController,
    private _consultaPagoMovil: ConsultaPagoMovilService
  ) { 
    let emited = false;
    this._internetService.testNetworkConnection()
    this._estadisticas.registrar(0);
    this.onLine = this._internetService.onLine;  
    this._internetService.internetState.subscribe((data: boolean) => { 
      
      if (!emited) {
        this.onLine = data;
        if (!this.onLine) {
          this._internetService.verifyConection(this.onLine, true);
          this.navCtrl.navigateForward('home');
        }
      }
      emited = true;
    });
    
  }

  ngOnInit() {
    // this._internetService.verifyConection(this.onLine, true);
    this.onPagoMovilForm = this._formBuilder.group({
      'numero_telefono': [null, Validators.compose([
        Validators.required,
      ])],
      'banco': [null, Validators.compose([
        Validators.required,
      ])],
      'monto': [null, Validators.compose([
        Validators.required,
      ])]
    });
    this.CedulaActual = this._localStorageService.get('CedulaActual');
    this.secureUrl = `${this.urlPagos}${this.CedulaActual.identidad}`;
    if (this.permitirPagoMovil) {
      this.getBancosNacionales();
    }
  }

  async getBancosNacionales() {
    try {
      const allBancos = await this._consultaPagoMovil.consultaBancos();
      const nacionales = allBancos.error ? [] : allBancos.data.filter((item: any) => item.nacional === 1);
      console.log(nacionales)
      this.bancos = nacionales;
    } catch (error) {
      this.bancos = [];
    }
   
  }

  editprofile() {
    this.navCtrl.navigateForward('edit-profile');
  }

  seleccionarPagoMovil() {
    this.transferencia = false;
    this.pagoMovil = true;
  }

  seleccionarTransferencia() {
    this.transferencia = true;
    this.pagoMovil = false;
  }

  procesarPago() {
    console.log({...this.onPagoMovilForm.value, pin: this.pin})
    this.fecha = new Date().toLocaleString();
    this.data = this.onPagoMovilForm.value;
    if (true) {
      this.pagado = true;
      this.failure = false;
      this.success = true;
    } else {
      this.pagado = true;
      this.success = false;
      this.failure = true;
    }
    this.onPagoMovilForm.reset();
  }

  
  async createAlert(header: string, message: string = "") {
    const alert = await this._alert.create({
      header,
      message,
      buttons: [
        {
          text: 'Ok',
          role: 'Cancelar',
          cssClass: 'secondary',
        }
      ]
    });
    alert.present()
  }

  solicitarPin() {
    this.pin = "vzkjxcpasoi41-04291-dfka;d"
  }

}
