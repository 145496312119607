import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EstadisticasService } from '../../../services/estadisticas.service';
import { SubscriptionGraphqlService } from "../../../services/subscription-graphql.service";
@Component({
  selector: 'app-suport-modal',
  templateUrl: './suport-modal.page.html',
  styleUrls: ['./suport-modal.page.scss'],
})
export class SuportModalPage implements OnInit {
  @Input() data: any;
  constructor(
    private modalCtrl: ModalController,
    private _SubscriptionGraphqlService: SubscriptionGraphqlService,
    private _estadisticas: EstadisticasService,
  ) {
    this._estadisticas.registrar(14)
   }

  ngOnInit() {
    

    this._SubscriptionGraphqlService.OpSelect.subscribe((op:any)=>{
      switch (op.option) {
        case "op3-1-1":
          this.closeModal();
          break;
      
        default:
          break;
      }

    })

  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}
