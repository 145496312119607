import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { SubscriptionGraphqlService } from "../../../services/subscription-graphql.service";
import gql from "graphql-tag";

@Component({
  selector: 'app-metodo-pago',
  templateUrl: './metodo-pago.page.html',
  styleUrls: ['./metodo-pago.page.scss'],
})
export class MetodoPagoPage implements OnInit {
  @Input() method: any;
  public paypalForm: FormGroup;
  public creditCardFrom: FormGroup;
  public currentMethod: string;
  constructor(
    private modalCtrl: ModalController,
    private formBuilder: FormBuilder,
    private _SubscriptionGraphqlService: SubscriptionGraphqlService
  ) { }

  ngOnInit() {
    this.modalCtrl.dismiss();
    this.currentMethod = this.method;
    this.paypalForm = this.formBuilder.group({
      'correoPayPal': [null, Validators.compose([
        Validators.required
      ])],
    } );
    this.creditCardFrom = this.formBuilder.group({
      'numTarjeta': [null, Validators.compose([
        Validators.required
      ])],
      'fullName': [null, Validators.compose([
        Validators.required
      ])],
      'Fecha': [null, Validators.compose([
        Validators.required,
      ])],
      'CVC': [null, Validators.compose([
        Validators.required,
      ])],
    });
  }
  
  closeModal() {
    this.modalCtrl.dismiss();
  }
}
