export const diccionario = [
    {
        "name": "-traffic-table_101",
        "velocidadMB": "2 MB",
        "velocidadKbps": "2176 kbps",
        "tipo": "Internet"
    },
    {
        "name": "DOWN_2M_VOIP",
        "velocidadMB": "3 MB",
        "velocidadKbps": "3072 kbps",
        "tipo": "Internet"
    },
    {
        "name": "FTTH_5",
        "velocidadMB": "4 MB",
        "velocidadKbps": "5096 kbps",
        "tipo": "Internet"
    },
    {
        "name": "D_FTTH_5",
        "velocidadMB": "5 MB",
        "velocidadKbps": "5120 kbps",
        "tipo": "Internet"
    },
    {
        "name": "PYME_8",
        "velocidadMB": "8 MB",
        "velocidadKbps": "8192 kbps",
        "tipo": "Internet"
    },
    {
        "name": "D_FTTH_8",
        "velocidadMB": "8 MB",
        "velocidadKbps": "8192 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_108",
        "velocidadMB": "8 MB",
        "velocidadKbps": "8192 kbps",
        "tipo": "Internet"
    },
    {
        "name": "FTTH_8",
        "velocidadMB": "8 MB",
        "velocidadKbps": "8192 kbps",
        "tipo": "Internet"
    },
    {
        "name": "D_FTTH_10",
        "velocidadMB": "10 MB",
        "velocidadKbps": "10240 kbps",
        "tipo": "Internet"
    },
    {
        "name": "10MB",
        "velocidadMB": "10 MB",
        "velocidadKbps": "10240 kbps",
        "tipo": "Internet"
    },
    {
        "name": "PYME_10M",
        "velocidadMB": "10 MB",
        "velocidadKbps": "10240 kbps",
        "tipo": "Internet"
    },
    {
        "name": "10MB-pymes",
        "velocidadMB": "10 MB",
        "velocidadKbps": "10240 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_110",
        "velocidadMB": "10 MB",
        "velocidadKbps": "10240 kbps",
        "tipo": "Internet"
    },
    {
        "name": "10M",
        "velocidadMB": "10 MB",
        "velocidadKbps": "10496 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_302",
        "velocidadMB": "10 MB",
        "velocidadKbps": "11008 kbps",
        "tipo": "Internet"
    },
    {
        "name": "15MB",
        "velocidadMB": "15 MB",
        "velocidadKbps": "15360 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_115",
        "velocidadMB": "15 MB",
        "velocidadKbps": "15616 kbps",
        "tipo": "Internet"
    },
    {
        "name": "15_MBps_PYME",
        "velocidadMB": "15 MB",
        "velocidadKbps": "16128 kbps",
        "tipo": "Internet"
    },
    {
        "name": "D_FTTH_16",
        "velocidadMB": "16 MB",
        "velocidadKbps": "16384 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_120",
        "velocidadMB": "19 MB",
        "velocidadKbps": "19968 kbps",
        "tipo": "Internet"
    },
    {
        "name": "20MB-pymes",
        "velocidadMB": "19 MB",
        "velocidadKbps": "20000 kbps",
        "tipo": "Internet"
    },
    {
        "name": "PYME_20M",
        "velocidadMB": "20 MB",
        "velocidadKbps": "20480 kbps",
        "tipo": "Internet"
    },
    {
        "name": "D_FTTH_20",
        "velocidadMB": "20 MB",
        "velocidadKbps": "20480 kbps",
        "tipo": "Internet"
    },
    {
        "name": "20MB",
        "velocidadMB": "20 MB",
        "velocidadKbps": "20480 kbps",
        "tipo": "Internet"
    },
    {
        "name": "20M",
        "velocidadMB": "20 MB",
        "velocidadKbps": "20992 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_303",
        "velocidadMB": "20 MB",
        "velocidadKbps": "20992 kbps",
        "tipo": "Internet"
    },
    {
        "name": "21M",
        "velocidadMB": "21 MB",
        "velocidadKbps": "21504 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_102",
        "velocidadMB": "21 MB",
        "velocidadKbps": "21888 kbps",
        "tipo": "Internet"
    },
    {
        "name": "25M",
        "velocidadMB": "25 MB",
        "velocidadKbps": "25600 kbps",
        "tipo": "Internet"
    },
    {
        "name": "30MB",
        "velocidadMB": "30 MB",
        "velocidadKbps": "30720 kbps",
        "tipo": "Internet"
    },
    {
        "name": "PYME_30M",
        "velocidadMB": "30 MB",
        "velocidadKbps": "30720 kbps",
        "tipo": "Internet"
    },
    {
        "name": "30MB-pymes",
        "velocidadMB": "30 MB",
        "velocidadKbps": "30720 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_330",
        "velocidadMB": "30 MB",
        "velocidadKbps": "30720 kbps",
        "tipo": "Internet"
    },
    {
        "name": "D_FTTH_30",
        "velocidadMB": "30 MB",
        "velocidadKbps": "30720 kbps",
        "tipo": "Internet"
    },
    {
        "name": "30M",
        "velocidadMB": "30 MB",
        "velocidadKbps": "31488 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_130",
        "velocidadMB": "31 MB",
        "velocidadKbps": "32000 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_380",
        "velocidadMB": "32 MB",
        "velocidadKbps": "32768 kbps",
        "tipo": "Internet"
    },
    {
        "name": "FTTH_35",
        "velocidadMB": "35 MB",
        "velocidadKbps": "35840 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_306",
        "velocidadMB": "39 MB",
        "velocidadKbps": "40448 kbps",
        "tipo": "Internet"
    },
    {
        "name": "PYME_40M",
        "velocidadMB": "40 MB",
        "velocidadKbps": "40960 kbps",
        "tipo": "Internet"
    },
    {
        "name": "D_FTTH_40",
        "velocidadMB": "40 MB",
        "velocidadKbps": "40960 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_140",
        "velocidadMB": "40 MB",
        "velocidadKbps": "40960 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_103",
        "velocidadMB": "41 MB",
        "velocidadKbps": "41984 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_305",
        "velocidadMB": "49 MB",
        "velocidadKbps": "50944 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_105",
        "velocidadMB": "49 MB",
        "velocidadKbps": "50944 kbps",
        "tipo": "Internet"
    },
    {
        "name": "D_FTTH_50",
        "velocidadMB": "50 MB",
        "velocidadKbps": "51200 kbps",
        "tipo": "Internet"
    },
    {
        "name": "PYME_50M",
        "velocidadMB": "50 MB",
        "velocidadKbps": "51200 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_150",
        "velocidadMB": "50 MB",
        "velocidadKbps": "51200 kbps",
        "tipo": "Internet"
    },
    {
        "name": "50M",
        "velocidadMB": "51 MB",
        "velocidadKbps": "52480 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_155",
        "velocidadMB": "54 MB",
        "velocidadKbps": "55488 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_160",
        "velocidadMB": "59 MB",
        "velocidadKbps": "60480 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_307",
        "velocidadMB": "59 MB",
        "velocidadKbps": "60672 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_460",
        "velocidadMB": "59 MB",
        "velocidadKbps": "60992 kbps",
        "tipo": "Internet"
    },
    {
        "name": "FTTH_60",
        "velocidadMB": "60 MB",
        "velocidadKbps": "61440 kbps",
        "tipo": "Internet"
    },
    {
        "name": "PYME_60",
        "velocidadMB": "60 MB",
        "velocidadKbps": "61440 kbps",
        "tipo": "Internet"
    },
    {
        "name": "D_FTTH_60",
        "velocidadMB": "60 MB",
        "velocidadKbps": "61440 kbps",
        "tipo": "Internet"
    },
    {
        "name": "60M",
        "velocidadMB": "61 MB",
        "velocidadKbps": "62976 kbps",
        "tipo": "Internet"
    },
    {
        "name": "D_FTTH_70",
        "velocidadMB": "70 MB",
        "velocidadKbps": "71680 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_180",
        "velocidadMB": "80 MB",
        "velocidadKbps": "81920 kbps",
        "tipo": "Internet"
    },
    {
        "name": "D_FTTH_80",
        "velocidadMB": "80 MB",
        "velocidadKbps": "81920 kbps",
        "tipo": "Internet"
    },
    {
        "name": "PYME_80",
        "velocidadMB": "80 MB",
        "velocidadKbps": "81920 kbps",
        "tipo": "Internet"
    },
    {
        "name": "raffic-table_304-U",
        "velocidadMB": "80 MB",
        "velocidadKbps": "81920 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_304",
        "velocidadMB": "80 MB",
        "velocidadKbps": "81920 kbps",
        "tipo": "Internet"
    },
    {
        "name": "D_FTTH_90",
        "velocidadMB": "90 MB",
        "velocidadKbps": "92160 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_308",
        "velocidadMB": "90 MB",
        "velocidadKbps": "92160 kbps",
        "tipo": "Internet"
    },
    {
        "name": "DOWN_30M_100M_OTT",
        "velocidadMB": "100 MB",
        "velocidadKbps": "102400 kbps",
        "tipo": "Internet"
    },
    {
        "name": "100MB",
        "velocidadMB": "100 MB",
        "velocidadKbps": "102400 kbps",
        "tipo": "Internet"
    },
    {
        "name": "FTTH_100",
        "velocidadMB": "100 MB",
        "velocidadKbps": "102400 kbps",
        "tipo": "Internet"
    },
    {
        "name": "D_FTTH_100",
        "velocidadMB": "100 MB",
        "velocidadKbps": "102400 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_310",
        "velocidadMB": "100 MB",
        "velocidadKbps": "102400 kbps",
        "tipo": "Internet"
    },
    {
        "name": "PYME_100M",
        "velocidadMB": "100 MB",
        "velocidadKbps": "102400 kbps",
        "tipo": "Internet"
    },
    {
        "name": "100M",
        "velocidadMB": "102 MB",
        "velocidadKbps": "104960 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_320",
        "velocidadMB": "120 MB",
        "velocidadKbps": "122880 kbps",
        "tipo": "Internet"
    },
    {
        "name": "150MB",
        "velocidadMB": "150 MB",
        "velocidadKbps": "153600 kbps",
        "tipo": "Internet"
    },
    {
        "name": "D_FTTH_150",
        "velocidadMB": "150 MB",
        "velocidadKbps": "153600 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_100",
        "velocidadMB": "196 MB",
        "velocidadKbps": "200960 kbps",
        "tipo": "Internet"
    },
    {
        "name": "PYME_200",
        "velocidadMB": "200 MB",
        "velocidadKbps": "204800 kbps",
        "tipo": "Internet"
    },
    {
        "name": "D_FTTH_200",
        "velocidadMB": "200 MB",
        "velocidadKbps": "204800 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_350",
        "velocidadMB": "200 MB",
        "velocidadKbps": "204800 kbps",
        "tipo": "Internet"
    },
    {
        "name": "FTTH_200",
        "velocidadMB": "204 MB",
        "velocidadKbps": "209664 kbps",
        "tipo": "Internet"
    },
    {
        "name": "200M",
        "velocidadMB": "204 MB",
        "velocidadKbps": "209664 kbps",
        "tipo": "Internet"
    },
    {
        "name": "FTTH_220",
        "velocidadMB": "220 MB",
        "velocidadKbps": "225280 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_400",
        "velocidadMB": "250 MB",
        "velocidadKbps": "256000 kbps",
        "tipo": "Internet"
    },
    {
        "name": "D_FTTH_250",
        "velocidadMB": "250 MB",
        "velocidadKbps": "256000 kbps",
        "tipo": "Internet"
    },
    {
        "name": "D_FTTH_300",
        "velocidadMB": "300 MB",
        "velocidadKbps": "307200 kbps",
        "tipo": "Internet"
    },
    {
        "name": "FTTH_300",
        "velocidadMB": "300 MB",
        "velocidadKbps": "307200 kbps",
        "tipo": "Internet"
    },
    {
        "name": "ip-traffic-table_7",
        "velocidadMB": "400 MB",
        "velocidadKbps": "409600 kbps",
        "tipo": "Internet"
    },
    {
        "name": "1G",
        "velocidadMB": "1023 MB",
        "velocidadKbps": "1048064 kbps",
        "tipo": "Internet"
    },
    {
        "name": "50M",
        "velocidadMB": "55 MB",
        "velocidadKbps": "56320 kbps",
        "tipo": "IPTV"
    },
    {
        "name": "Name",
        "velocidadMB": "Speed",
        "velocidadKbps": "Speed",
        "tipo": "Type"
    },
    {
        "name": "UP_1M_TR069",
        "velocidadMB": "1 MB",
        "velocidadKbps": "1024 kbps",
        "tipo": "Internet"
    },
    {
        "name": "ID-10",
        "velocidadMB": "1 MB",
        "velocidadKbps": "1024 kbps",
        "tipo": "Internet"
    },
    {
        "name": "UP_1M_VOIP",
        "velocidadMB": "1 MB",
        "velocidadKbps": "1024 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_101",
        "velocidadMB": "2 MB",
        "velocidadKbps": "2176 kbps",
        "tipo": "Internet"
    },
    {
        "name": "TCONT_4M",
        "velocidadMB": "4 MB",
        "velocidadKbps": "4096 kbps",
        "tipo": "Internet"
    },
    {
        "name": "FTTH_5",
        "velocidadMB": "4 MB",
        "velocidadKbps": "5096 kbps",
        "tipo": "Internet"
    },
    {
        "name": "D_FTTH_5",
        "velocidadMB": "5 MB",
        "velocidadKbps": "5120 kbps",
        "tipo": "Internet"
    },
    {
        "name": "PYME_8",
        "velocidadMB": "8 MB",
        "velocidadKbps": "8192 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_108",
        "velocidadMB": "8 MB",
        "velocidadKbps": "8192 kbps",
        "tipo": "Internet"
    },
    {
        "name": "FTTH_8",
        "velocidadMB": "8 MB",
        "velocidadKbps": "8192 kbps",
        "tipo": "Internet"
    },
    {
        "name": "D_FTTH_8",
        "velocidadMB": "8 MB",
        "velocidadKbps": "8192 kbps",
        "tipo": "Internet"
    },
    {
        "name": "10MB-pymes",
        "velocidadMB": "10 MB",
        "velocidadKbps": "10240 kbps",
        "tipo": "Internet"
    },
    {
        "name": "FTTH_10",
        "velocidadMB": "10 MB",
        "velocidadKbps": "10240 kbps",
        "tipo": "Internet"
    },
    {
        "name": "TCONT_10M",
        "velocidadMB": "10 MB",
        "velocidadKbps": "10240 kbps",
        "tipo": "Internet"
    },
    {
        "name": "ID-12",
        "velocidadMB": "10 MB",
        "velocidadKbps": "10240 kbps",
        "tipo": "Internet"
    },
    {
        "name": "10MB",
        "velocidadMB": "10 MB",
        "velocidadKbps": "10240 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_110",
        "velocidadMB": "10 MB",
        "velocidadKbps": "10240 kbps",
        "tipo": "Internet"
    },
    {
        "name": "PYME_10M",
        "velocidadMB": "10 MB",
        "velocidadKbps": "10240 kbps",
        "tipo": "Internet"
    },
    {
        "name": "UP_10M_OTT",
        "velocidadMB": "10 MB",
        "velocidadKbps": "10240 kbps",
        "tipo": "Internet"
    },
    {
        "name": "D_FTTH_10",
        "velocidadMB": "10 MB",
        "velocidadKbps": "10240 kbps",
        "tipo": "Internet"
    },
    {
        "name": "10M",
        "velocidadMB": "10 MB",
        "velocidadKbps": "10496 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_302",
        "velocidadMB": "10 MB",
        "velocidadKbps": "11008 kbps",
        "tipo": "Internet"
    },
    {
        "name": "ip-traffic-table_4",
        "velocidadMB": "12 MB",
        "velocidadKbps": "12288 kbps",
        "tipo": "Internet"
    },
    {
        "name": "15MB",
        "velocidadMB": "15 MB",
        "velocidadKbps": "15360 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_115",
        "velocidadMB": "15 MB",
        "velocidadKbps": "15616 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_116",
        "velocidadMB": "15 MB",
        "velocidadKbps": "16000 kbps",
        "tipo": "Internet"
    },
    {
        "name": "15_MBps_PYME",
        "velocidadMB": "15 MB",
        "velocidadKbps": "16128 kbps",
        "tipo": "Internet"
    },
    {
        "name": "D_FTTH_16",
        "velocidadMB": "16 MB",
        "velocidadKbps": "16384 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_120",
        "velocidadMB": "19 MB",
        "velocidadKbps": "19968 kbps",
        "tipo": "Internet"
    },
    {
        "name": "20MB-pymes",
        "velocidadMB": "19 MB",
        "velocidadKbps": "20000 kbps",
        "tipo": "Internet"
    },
    {
        "name": "20MB",
        "velocidadMB": "20 MB",
        "velocidadKbps": "20480 kbps",
        "tipo": "Internet"
    },
    {
        "name": "PYME_20M",
        "velocidadMB": "20 MB",
        "velocidadKbps": "20480 kbps",
        "tipo": "Internet"
    },
    {
        "name": "raffic-table_120-D",
        "velocidadMB": "20 MB",
        "velocidadKbps": "20480 kbps",
        "tipo": "Internet"
    },
    {
        "name": "D_FTTH_20",
        "velocidadMB": "20 MB",
        "velocidadKbps": "20480 kbps",
        "tipo": "Internet"
    },
    {
        "name": "20M",
        "velocidadMB": "20 MB",
        "velocidadKbps": "20992 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_303",
        "velocidadMB": "20 MB",
        "velocidadKbps": "20992 kbps",
        "tipo": "Internet"
    },
    {
        "name": "21M",
        "velocidadMB": "21 MB",
        "velocidadKbps": "21504 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_102",
        "velocidadMB": "21 MB",
        "velocidadKbps": "21888 kbps",
        "tipo": "Internet"
    },
    {
        "name": "25M",
        "velocidadMB": "25 MB",
        "velocidadKbps": "25600 kbps",
        "tipo": "Internet"
    },
    {
        "name": "PYME_30M",
        "velocidadMB": "30 MB",
        "velocidadKbps": "30720 kbps",
        "tipo": "Internet"
    },
    {
        "name": "30MB-pymes",
        "velocidadMB": "30 MB",
        "velocidadKbps": "30720 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_330",
        "velocidadMB": "30 MB",
        "velocidadKbps": "30720 kbps",
        "tipo": "Internet"
    },
    {
        "name": "D_FTTH_30",
        "velocidadMB": "30 MB",
        "velocidadKbps": "30720 kbps",
        "tipo": "Internet"
    },
    {
        "name": "30MB",
        "velocidadMB": "30 MB",
        "velocidadKbps": "30720 kbps",
        "tipo": "Internet"
    },
    {
        "name": "30M",
        "velocidadMB": "30 MB",
        "velocidadKbps": "31488 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_130",
        "velocidadMB": "31 MB",
        "velocidadKbps": "32000 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_380",
        "velocidadMB": "32 MB",
        "velocidadKbps": "32768 kbps",
        "tipo": "Internet"
    },
    {
        "name": "FTTH_35",
        "velocidadMB": "35 MB",
        "velocidadKbps": "35840 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_135",
        "velocidadMB": "36 MB",
        "velocidadKbps": "37120 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_306",
        "velocidadMB": "39 MB",
        "velocidadKbps": "40448 kbps",
        "tipo": "Internet"
    },
    {
        "name": "PYME_40M",
        "velocidadMB": "40 MB",
        "velocidadKbps": "40960 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_140",
        "velocidadMB": "40 MB",
        "velocidadKbps": "40960 kbps",
        "tipo": "Internet"
    },
    {
        "name": "D_FTTH_40",
        "velocidadMB": "40 MB",
        "velocidadKbps": "40960 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_103",
        "velocidadMB": "41 MB",
        "velocidadKbps": "41984 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_105",
        "velocidadMB": "49 MB",
        "velocidadKbps": "50944 kbps",
        "tipo": "Internet"
    },
    {
        "name": "PYME_50M",
        "velocidadMB": "50 MB",
        "velocidadKbps": "51200 kbps",
        "tipo": "Internet"
    },
    {
        "name": "D_FTTH_50",
        "velocidadMB": "50 MB",
        "velocidadKbps": "51200 kbps",
        "tipo": "Internet"
    },
    {
        "name": "TCONT_50M",
        "velocidadMB": "50 MB",
        "velocidadKbps": "51200 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_150",
        "velocidadMB": "50 MB",
        "velocidadKbps": "51200 kbps",
        "tipo": "Internet"
    },
    {
        "name": "50M",
        "velocidadMB": "51 MB",
        "velocidadKbps": "52480 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_155",
        "velocidadMB": "54 MB",
        "velocidadKbps": "55488 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_160",
        "velocidadMB": "59 MB",
        "velocidadKbps": "60480 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_307",
        "velocidadMB": "59 MB",
        "velocidadKbps": "60672 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_460",
        "velocidadMB": "59 MB",
        "velocidadKbps": "60992 kbps",
        "tipo": "Internet"
    },
    {
        "name": "PYME_60",
        "velocidadMB": "60 MB",
        "velocidadKbps": "61440 kbps",
        "tipo": "Internet"
    },
    {
        "name": "D_FTTH_60",
        "velocidadMB": "60 MB",
        "velocidadKbps": "61440 kbps",
        "tipo": "Internet"
    },
    {
        "name": "FTTH_60",
        "velocidadMB": "60 MB",
        "velocidadKbps": "61440 kbps",
        "tipo": "Internet"
    },
    {
        "name": "60M",
        "velocidadMB": "61 MB",
        "velocidadKbps": "62976 kbps",
        "tipo": "Internet"
    },
    {
        "name": "D_FTTH_70",
        "velocidadMB": "70 MB",
        "velocidadKbps": "71680 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_180",
        "velocidadMB": "80 MB",
        "velocidadKbps": "81920 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_304",
        "velocidadMB": "80 MB",
        "velocidadKbps": "81920 kbps",
        "tipo": "Internet"
    },
    {
        "name": "PYME_80",
        "velocidadMB": "80 MB",
        "velocidadKbps": "81920 kbps",
        "tipo": "Internet"
    },
    {
        "name": "D_FTTH_80",
        "velocidadMB": "80 MB",
        "velocidadKbps": "81920 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_305",
        "velocidadMB": "80 MB",
        "velocidadKbps": "81920 kbps",
        "tipo": "Internet"
    },
    {
        "name": "FTTH_50",
        "velocidadMB": "80 MB",
        "velocidadKbps": "81920 kbps",
        "tipo": "Internet"
    },
    {
        "name": "raffic-table_304-D",
        "velocidadMB": "80 MB",
        "velocidadKbps": "81920 kbps",
        "tipo": "Internet"
    },
    {
        "name": "80M",
        "velocidadMB": "82 MB",
        "velocidadKbps": "83968 kbps",
        "tipo": "Internet"
    },
    {
        "name": "FTTH_90",
        "velocidadMB": "87 MB",
        "velocidadKbps": "90000 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_308",
        "velocidadMB": "90 MB",
        "velocidadKbps": "92160 kbps",
        "tipo": "Internet"
    },
    {
        "name": "D_FTTH_90",
        "velocidadMB": "90 MB",
        "velocidadKbps": "92160 kbps",
        "tipo": "Internet"
    },
    {
        "name": "90M",
        "velocidadMB": "92 MB",
        "velocidadKbps": "94464 kbps",
        "tipo": "Internet"
    },
    {
        "name": "100MB",
        "velocidadMB": "100 MB",
        "velocidadKbps": "102400 kbps",
        "tipo": "Internet"
    },
    {
        "name": "TCONT_100M",
        "velocidadMB": "100 MB",
        "velocidadKbps": "102400 kbps",
        "tipo": "Internet"
    },
    {
        "name": "FTTH_100",
        "velocidadMB": "100 MB",
        "velocidadKbps": "102400 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_310",
        "velocidadMB": "100 MB",
        "velocidadKbps": "102400 kbps",
        "tipo": "Internet"
    },
    {
        "name": "UP_6M_100M_KUANDAI",
        "velocidadMB": "100 MB",
        "velocidadKbps": "102400 kbps",
        "tipo": "Internet"
    },
    {
        "name": "UP_5M_100M_OTT",
        "velocidadMB": "100 MB",
        "velocidadKbps": "102400 kbps",
        "tipo": "Internet"
    },
    {
        "name": "D_FTTH_100",
        "velocidadMB": "100 MB",
        "velocidadKbps": "102400 kbps",
        "tipo": "Internet"
    },
    {
        "name": "PYME_100M",
        "velocidadMB": "100 MB",
        "velocidadKbps": "102400 kbps",
        "tipo": "Internet"
    },
    {
        "name": "100M",
        "velocidadMB": "102 MB",
        "velocidadKbps": "104960 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_320",
        "velocidadMB": "120 MB",
        "velocidadKbps": "122880 kbps",
        "tipo": "Internet"
    },
    {
        "name": "120MB",
        "velocidadMB": "120 MB",
        "velocidadKbps": "122880 kbps",
        "tipo": "Internet"
    },
    {
        "name": "120M",
        "velocidadMB": "120 MB",
        "velocidadKbps": "123136 kbps",
        "tipo": "Internet"
    },
    {
        "name": "150MB",
        "velocidadMB": "150 MB",
        "velocidadKbps": "153600 kbps",
        "tipo": "Internet"
    },
    {
        "name": "D_FTTH_150",
        "velocidadMB": "150 MB",
        "velocidadKbps": "153600 kbps",
        "tipo": "Internet"
    },
    {
        "name": "160MB",
        "velocidadMB": "160 MB",
        "velocidadKbps": "163840 kbps",
        "tipo": "Internet"
    },
    {
        "name": "165MB",
        "velocidadMB": "165 MB",
        "velocidadKbps": "168960 kbps",
        "tipo": "Internet"
    },
    {
        "name": "180MB",
        "velocidadMB": "180 MB",
        "velocidadKbps": "184320 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_100",
        "velocidadMB": "196 MB",
        "velocidadKbps": "200960 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_350",
        "velocidadMB": "200 MB",
        "velocidadKbps": "204800 kbps",
        "tipo": "Internet"
    },
    {
        "name": "PYME_200",
        "velocidadMB": "200 MB",
        "velocidadKbps": "204800 kbps",
        "tipo": "Internet"
    },
    {
        "name": "D_FTTH_200",
        "velocidadMB": "200 MB",
        "velocidadKbps": "204800 kbps",
        "tipo": "Internet"
    },
    {
        "name": "200M",
        "velocidadMB": "204 MB",
        "velocidadKbps": "209664 kbps",
        "tipo": "Internet"
    },
    {
        "name": "FTTH_200",
        "velocidadMB": "204 MB",
        "velocidadKbps": "209664 kbps",
        "tipo": "Internet"
    },
    {
        "name": "FTTH_220",
        "velocidadMB": "220 MB",
        "velocidadKbps": "225280 kbps",
        "tipo": "Internet"
    },
    {
        "name": "D_FTTH_250",
        "velocidadMB": "248 MB",
        "velocidadKbps": "254000 kbps",
        "tipo": "Internet"
    },
    {
        "name": "-traffic-table_400",
        "velocidadMB": "250 MB",
        "velocidadKbps": "256000 kbps",
        "tipo": "Internet"
    },
    {
        "name": "_36M_250M_KUANDAI",
        "velocidadMB": "250 MB",
        "velocidadKbps": "256000 kbps",
        "tipo": "Internet"
    },
    {
        "name": "_10M_250M_KUANDAI",
        "velocidadMB": "250 MB",
        "velocidadKbps": "256000 kbps",
        "tipo": "Internet"
    },
    {
        "name": "D_FTTH_300",
        "velocidadMB": "300 MB",
        "velocidadKbps": "307200 kbps",
        "tipo": "Internet"
    },
    {
        "name": "FTTH_300",
        "velocidadMB": "300 MB",
        "velocidadKbps": "307200 kbps",
        "tipo": "Internet"
    },
    {
        "name": "350M",
        "velocidadMB": "350 MB",
        "velocidadKbps": "358400 kbps",
        "tipo": "Internet"
    },
    {
        "name": "ID-11",
        "velocidadMB": "400 MB",
        "velocidadKbps": "409600 kbps",
        "tipo": "Internet"
    },
    {
        "name": "ip-traffic-table_7",
        "velocidadMB": "400 MB",
        "velocidadKbps": "409600 kbps",
        "tipo": "Internet"
    },
    {
        "name": "ID-14",
        "velocidadMB": "976 MB",
        "velocidadKbps": "1000000 kbps",
        "tipo": "Internet"
    },
    {
        "name": "ID-13",
        "velocidadMB": "1023 MB",
        "velocidadKbps": "1048000 kbps",
        "tipo": "Internet"
    },
    {
        "name": "1G",
        "velocidadMB": "1023 MB",
        "velocidadKbps": "1048064 kbps",
        "tipo": "Internet"
    },
    {
        "name": "10M",
        "velocidadMB": "11 MB",
        "velocidadKbps": "11264 kbps",
        "tipo": "IPTV"
    },
    {
        "name": "10M-PYME;",
        "velocidadMB": "10 MB",
        "velocidadKbps": "10264 kbps",
        "tipo": "internet"
    }
]