import { Component, Input, OnInit } from "@angular/core";
import { AlertController, ModalController } from "@ionic/angular";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { ConsultasService } from "../../../services/consultas.service";
import { SubscriptionGraphqlService } from "../../../services/subscription-graphql.service";

import { LocalStorageService } from '../../../services/local-storage.service';
import { LogErrorsService } from "../../../services/log-errors.service";

@Component({
  selector: "app-upgrate-service",
  templateUrl: "./upgrate-service.page.html",
  styleUrls: ["./upgrate-service.page.scss"],
})
export class UpgrateServicePage implements OnInit {
  @Input() PlanActual: string = "";
  @Input() DatosUser: any;
  @Input() DatosEquipo: any;
  @Input() pack: any;
  public formService: FormGroup;

  DisableButons: boolean = false

  NewCapacity
  NewPrice

  UserInfo: any

  ServiciosActuales: any

  infoServicio: any

  TengoDatos: Boolean = false

  constructor(
    private modal: ModalController,
    private fb: FormBuilder,
    private consultasS: ConsultasService,
    private _SubscriptionGraphqlService: SubscriptionGraphqlService,
    private _localStorageService: LocalStorageService,
    private alertController: AlertController,
    private _logs: LogErrorsService,
  ) {

    this.UserInfo = this._localStorageService.get('user')

  }

  ngOnInit() {

    this._SubscriptionGraphqlService.OpSelect.subscribe((op: any) => {
      switch (op.option) {
        case "op5-3":
          this.closeModal();
          break;

        default:
          break;
      }

    })


    this.consultasS.ListService().then((ResService: any) => {

      this.infoServicio = ResService

      if (this.PlanActual.includes("PYME")) {

        this.ServiciosActuales = this.infoServicio.filter((service) => service.Categoria === "PYME");

      } else {
        this.ServiciosActuales = this.infoServicio.filter((service) => service.Categoria != "PYME");
      }

      this.formService = this.fb.group({
        serviceSelect: this.ServiciosActuales
      });

      this.TengoDatos = true

    }).catch(error => {
      this._logs.insertarError(error.message);
    })

  }

  get serviceSelect() { return this.formService.get("serviceSelect") }

  closeModal() {
    this.modal.dismiss(this.DisableButons);
  }

  AddUpgrade() {
    try {

      const pin = Math.random().toString(16).slice(3, 9);

      const DataService = {
        'PlanActual': this.PlanActual,
        Accion: "UPGRATE",
        ...this.DatosUser,
        ...this.DatosEquipo,
        ...this.pack
      }

      this.consultasS.sendMail(this.UserInfo.email, "Confirmación de Upgrate", `Usted ha solicitado un Upgrate de servicios a ${DataService.Capacidad} con una mensualidad de ${DataService.Precio}. <br><br> Su código de verificación es: <strong>${pin}</strong><br><br> Si usted no solicito un upgrate de servicio puede que otra persona este intentando solicitarlo`).then((ResEmail: any) => {

        this.VerifyUpgrate(pin, this.UserInfo.email, DataService)

      }).catch((err: any) => {
        console.error(err)
      })


    } catch (error) {
      this._logs.insertarError(error.message);
    }
  }

  async presentAlertMultipleButtons(header: string, msg: string) {
    const alert = await this.alertController.create({
      header: header,
      message: msg,
      buttons: ['Aceptar']
    });

    await alert.present();
  }

  async VerifyUpgrate(Pin: any, Email: any, DataS: any) {
    try {

      const Pos = Email.indexOf('@')

      let HideLetter = ''

      for (let index = 0; index < Pos - 6; index++) {
        HideLetter = HideLetter + '*'
      }

      const correo = `${Email.substring(0, 3)}${HideLetter}${Email.substring(Pos - 3, Email.length)}`


      const alert = await this.alertController.create({
        header: 'Pin de confirmación',
        message: `Introduzca el pin que le llegó al correo ${correo} para poder aplicar el upgrade`,
        inputs: [
          {
            name: 'pin',
            type: 'email',
            placeholder: 'Pin de confirmación',
          }
        ],
        buttons: [
          {
            text: 'Cancelar',
            role: 'cancel',
            cssClass: 'secondary',
          }, {
            text: 'Confirmar',
            handler: async (data) => {

              if (data.pin) {

                if (data.pin === Pin) {
                  this.ApplyUpgrade(DataS)
                } else {
                  this.presentAlertMultipleButtons('Error de confirmación', 'El pin ingresado es incorrecto')
                }

              } else { return }

            }
          }
        ]
      });

      await alert.present();

    } catch (error) {
      console.error(error)
    }
  }


  ApplyUpgrade(DatosServicio: any) {
    try {

      if (DatosServicio) {
        this.consultasS.TurBoUpgrade(DatosServicio).then((Res: any) => {
          this.DisableButons = true
          this.presentAlertMultipleButtons('Solicitud', 'Su solicitud de upgrade se encuentra en proceso, una vez que se aplique será contactado')
          // alert("Su solicitud de upgrade se encuentra en proceso, una vez que se aplique será contactado")
          this._localStorageService.set('SolicitudUpTur', new Date())
        }).catch(error => {
          this._logs.insertarError(error.message);
        })
      }

    } catch (error) {
      console.error(error)
    }
  }

}
