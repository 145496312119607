import { Injectable, EventEmitter } from '@angular/core';
import { UserInfo } from '../interfaces/userInfo';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class CedulaStorage {
  public stateCedulas : EventEmitter<any> = new EventEmitter<any>()
  constructor(
    private _localStorageService: LocalStorageService,
  ) { 
    
  }

  public setCedula(userInfo: UserInfo, consultaData: any) {
    const data = this.getCedulas();
    const repited = this._checkRepited(data, userInfo.identidad);
    if (repited) {
      return true;
    }

    const numContratos = this._getNContratos(consultaData);
    const cuenta = {
      ...userInfo, 
      numContratos
    };

    const updated = [...data, cuenta];
    this._localStorageService.set('cedulas', updated);
    this.stateCedulas.emit(updated);
    return false;
  }

  public getCedulas() {
    return this._localStorageService.get('cedulas') || [];
  }

  public deleteCedula(identidad: string) {
    const data = this.getCedulas();
    if (data.length <= 1) {
      return;
    }
    const updated = data.filter((item: UserInfo) => item.identidad !== identidad);
    this._localStorageService.set('cedulas', updated);
    this.stateCedulas.emit(updated);
  }

  private _checkRepited(data: UserInfo[], identidad: string) {
    return data.map((item: UserInfo) => item.identidad).includes(identidad);
  }

  public editCedula(identidad: string, userInfo: UserInfo, consultaData: any) {
    const data = this.getCedulas();
    if (identidad !== userInfo.identidad && this._checkRepited(data, userInfo.identidad)) {
      return true;
    }
    const numContratos = this._getNContratos(consultaData);
    const cuenta = {
      ...userInfo, 
      numContratos
    };
    const updated = data.map((item: UserInfo) => item.identidad === identidad ? cuenta : item);
    this._localStorageService.set('cedulas', updated);
    this.stateCedulas.emit(updated);
    return false;
  }

  private _getNContratos(consultaData: any) {
    return consultaData.map((data:any) => data.nro_contrato);
  }

  public getFisrt() {
    const cedulas = this.getCedulas();

    if (!cedulas.length) {
      return null;
    }

    const first = {
      id: 1,
      Cedula : `${cedulas[0].numContratos.length > 1 ? `${cedulas[0].identidad} (${cedulas[0].numContratos[0]})` : `${cedulas[0].identidad}`}`,
      identidad : cedulas[0].identidad,
      NContrato : cedulas[0].numContratos[0],
    } 

    return first;
  }
}