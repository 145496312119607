import { Component, Input, OnInit } from '@angular/core';
import { NavController, ModalController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { CedulaStorage } from '../../../services/cedula-storage.service';
import { SubscriptionGraphqlService } from "../../../services/subscription-graphql.service";
import gql from "graphql-tag";
import { LocalStorageService } from '../../../services/local-storage.service';

@Component({
  selector: 'app-list-cedula',
  templateUrl: './list-cedula.page.html',
  styleUrls: ['./list-cedula.page.scss'],
})
export class ListCedulaPage implements OnInit {
  @Input() reciente: any;

  ListCedula: any = []
  RecentCedula: any = []

  searchterm: string = '';
  results: any;

  item:any 
  
  constructor(
    //private navCtrl: NavController,
    private modalCtrl: ModalController,
    private storage: Storage,
    private cedulaStorage: CedulaStorage,
    private _SubscriptionGraphqlService: SubscriptionGraphqlService,
    private _localStorageService: LocalStorageService,
  ) { }

  ngOnInit() {
    this._SubscriptionGraphqlService.OpSelect.subscribe((op:any)=>{
      switch (op.option) {
        case "op0-4":
          this.closeModal();
          break;
      
        default:
          break;
      }

    })
    this.GetCedulas()

    this.RecentCedula = this.reciente

  }

  setFilteredItems(ev: any) {
    let val = ev ? ev.target.value : '';

    // if the value is an empty string don't filter the items
    if (val && val.trim() !== '') {
      // this.showItems = true;
      this.results = this.ListCedula.filter((item) => {
        return (item.Cedula.toLowerCase().indexOf(val.toLowerCase()) > -1);
      });
    } else {
      this.results = this.ListCedula;
    }
  }

  searchBy(item:any) {

    this._localStorageService.set('cedulaCliente', item);

    this.modalCtrl.dismiss(item);
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  GetCedulas() {
    const listCedulas = this.formatList(this.cedulaStorage.getCedulas());
    this.results = listCedulas;

  }

  formatList(listCedulas: any) {
    if (!listCedulas.length) {
      return [];
    }
    const list = [];

    for (let i = 0; i < listCedulas.length; i++) {
      for (let j = 0; j < listCedulas[i].numContratos.length; j++) {
        const item = {
          Cedula : `${listCedulas[i].numContratos.length > 1 ? `${listCedulas[i].identidad} (${listCedulas[i].numContratos[j]})` : `${listCedulas[i].identidad}`}`,
          identidad : listCedulas[i].identidad,
          NContrato : listCedulas[i].numContratos[j],
        }
        list.push(item);
      }
      
    }
    return list.map((item:any, index: number) => ({id: index + 1, ...item}));
  }

}
