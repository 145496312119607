import { Component, Input, OnInit, } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertController, ModalController } from '@ionic/angular';
import { LocalStorageService } from '../../../services/local-storage.service';

@Component({
  selector: 'app-pin-validation',
  templateUrl: './pin-validation.page.html',
  styleUrls: ['./pin-validation.page.scss'],
})
export class PinValidationPage implements OnInit {
  @Input() data: any;
  public pinValidationForm: FormGroup;

  constructor(
    private modalCtrl: ModalController,
    private _localStorageService: LocalStorageService,
    private formBuilder: FormBuilder,
    private alert: AlertController,
  ) { 

  }

  ngOnInit() {
    this.pinValidationForm = this.formBuilder.group({
      'pinValue': [null, Validators.compose([
        Validators.required
      ])],
    });
  }

  getPin() {    
    const pinregister = this._localStorageService.get('pinRegister');
    if(!pinregister) {
      this.createAlert('Aun no ha creado un  pin de acceso', 'Para acceder a contenido restringido necesita crear un pin de acceso, pulse el botton de "Control parental" para crearlo.');
      this.closeModal();
      return;
    }
    const isValid = pinregister === this.pinValidationForm.value.pinValue;
    if (isValid) {
      this.modalCtrl.dismiss("OK");
      return;
    } else {
      this.createAlert('Pin invalido', 'El pin es incorrecto, por favor introduzca el pin de acceso a contenido restringido');
    }
    this.closeModal()
  }

  async createAlert(header: string, message: string = '') {
    const alert = await this.alert.create({
      header,
      message,
      buttons: [
        {
          text: 'Ok',
          role: 'Cancelar',
          cssClass: 'secondary',
        }
      ]
    });
    alert.present();
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}