import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ShowSaldoService {
  public menu : EventEmitter<any> = new EventEmitter<any>()

  constructor() { }

  setMenu(url: string) {
    this.menu.emit(url);
  }
}
