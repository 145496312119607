// Add your providers here for easy indexing.
export { TranslateProvider } from "./translate/translate.service";
export { HotelProvider } from "./hotel/hotel.service";
export { WeatherService } from "./weather/weather.service";
export { CarsService } from "./cars/cars.service";
export { ActivitiesService } from "./activities/activities.service";

export const OptionMenu = [
  {
      "title": "Saldo",
      "url": "/home",
      "direct": "root",
      "icon": "logo-usd",
      "show": true,
      "image": 'assets/img/icons/Iconos1.jpg'
  },
  {
      "title": "Reportar Pago",
      "url": "/favorites",
      "direct": "forward",
      "icon": "Cash",
      "show": true,
      "image": 'assets/img/icons/Iconos2.jpg'
  },
  {
      "title": "Fibex Play",
      "url": "/local-weather",
      "direct": "forward",
      "icon": "ios-desktop",
      "show": true,
      "image": 'assets/img/icons/Iconos4.jpg'
  },{
      "title": "Soporte",
      "url": "/support",
      "direct": "forward",
      "icon": "build",
      "show": true,
      "image": 'assets/img/icons/Iconos5.jpg'
  },
  {
      "title": "Promociones",
      "url": "/booking-list",
      "direct": "forward",
      "icon": "book",
      "show": true,
      "image": 'assets/img/icons/Iconos6.jpg'
  },
  {
      "title": "Servicios",
      "url": "/services",
      "direct": "forward",
      "icon": "easel",
      "show": true,
      "image": 'assets/img/icons/Iconos3.jpg'
  },  
  {
      "title": "Abonado(s)",
      "url": "/rentcar",
      "direct": "forward",
      "icon": "ios-people",
      "show": true,
      "image": 'assets/img/icons/Iconos7.jpg'
  },
  {
    "title": "Mi equipo",
    "url": "/mi-equipo",
    "direct": "forward",
    "icon": "laptop",
    "show": true,
    "image": 'assets/img/icons/mi-equipo.png'
  }, 
  {
    "title": "Referidos",
    "url": "/referidos",
    "direct": "forward",
    "icon": "person-add",
    "show": true,
    "image": 'assets/img/icons/referidos.png'
  },
  {
      "title": "Contacto",
      "url": "/activities",
      "direct": "forward",
      "icon": "md-people",
      "show": true,
      "image": 'assets/img/icons/Iconos8.jpg'
  },
  {
      "title": "Acerca de",
      "url": "/about",
      "direct": "forward",
      "icon": "md-information-circle",
      "show": true,
      "image": 'assets/img/icons/Iconos App (1).jpg'
  }
]

export const metodosPagoImages = [
    "assets/img/metodos-pago-images/Paypal_Mesa de trabajo 1.png",
    "assets/img/metodos-pago-images/BitCoin_Mesa de trabajo 1.png",
    "assets/img/metodos-pago-images/MasterCard_Mesa de trabajo 1.png",
    "assets/img/metodos-pago-images/Mercantil_Mesa de trabajo 1.png",
    "assets/img/metodos-pago-images/Ethereum_Mesa de trabajo 1.png",
    "assets/img/metodos-pago-images/Visa_Mesa de trabajo 1.png",
    "assets/img/metodos-pago-images/BNC_Mesa de trabajo 1.png",
    "assets/img/metodos-pago-images/Zelle_Mesa de trabajo 1.png",
]