import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { UserAuthenticationService } from '../../services/user-authentication.service';
import { SubscriptionGraphqlService } from "../../services/subscription-graphql.service";
import gql from "graphql-tag";
import { SuportModalPage } from '../modal/suport-modal/suport-modal.page';
import { HttpClient } from '@angular/common/http';
import { ConsultasService } from '../../services/consultas.service';
import { ReporteAveriaModalPage } from '../modal/reporte-averia-modal/reporte-averia-modal.page';
import { LocalStorageService } from '../../services/local-storage.service';
import { VerifyInternetStatusService } from '../../services/verify-internet-status.service';
import { EstadisticasService } from '../../services/estadisticas.service';
import { LogErrorsService } from '../../services/log-errors.service';

const moment = require('moment/moment');
@Component({
  selector: 'app-support',
  templateUrl: './support.page.html',
  styleUrls: ['./support.page.scss'],
})
export class SupportPage implements OnInit {
  public onLine: boolean = true;
  public tableSoport: any = [];
  private counter: number = 0;
  FechaActual = moment()

  constructor(
    private navCtrl: NavController,
    private sanatizer: DomSanitizer,
    private _SubscriptionGraphqlService: SubscriptionGraphqlService,
    private modalCtrl: ModalController,
    private consulta: ConsultasService,
    private _localStorageService: LocalStorageService,
    private _internetService: VerifyInternetStatusService,
    private _estadisticas: EstadisticasService,
    private _logs: LogErrorsService,
  ) {
    this._estadisticas.registrar(2);
    this._internetService.testNetworkConnection()
    this.onLine = this._internetService.onLine;
    this._internetService.internetState.subscribe((data: boolean) => {
      this.onLine = data;
      if (!this.onLine) {
        this._internetService.verifyConection(this.onLine, true);
        this.navCtrl.navigateForward('home');
      }
      // this._internetService.verifyConection(this.onLine||data, true);
    });
  }

  ngOnInit() {

    this.getSoporteData();
    this._SubscriptionGraphqlService.OpSelect.subscribe((op: any) => {
      switch (op.option) {
        case "op3-1":
          this.openSuportModal(this.tableSoport[0])
          break;
        case "op3-2":
          this.openReporteModal()
          break;

      }

    })

  }



  async getSoporteData() {
    try {
      const idContrato = this._localStorageService.get('CedulaActual').id_contrato;
      const data: any = await this.consulta.GetHistorialOrdenes(idContrato);
      //console.log(data)

      for (let indexData = 0; indexData < data.length; indexData++) {
        if (this.CalcularMeses(data[indexData].fecha_orden) < 6) {
          this.tableSoport.push(data[indexData])
        }
      }

      //this.tableSoport = data;
    } catch (error) {
      this._logs.insertarError(error.message);
      this.tableSoport = [];
    }

  }

  CalcularMeses(FRegistro: any) {
    var FechaRegistro = moment(FRegistro)
    return this.FechaActual.diff(FechaRegistro, 'months')
  }

  async openSuportModal(Detal: any) {
    if (!Detal) {
      return;
    }

    const modal = await this.modalCtrl.create({
      component: SuportModalPage,
      componentProps: { data: Detal }
    });
    await modal.present();
  }

  editprofile() {
    this.navCtrl.navigateForward('edit-profile');
  }

  MostrarDetal(Detal: any) {
    this.openSuportModal(Detal);
  }

  async openReporteModal() {
    if (!this.onLine) {
      this._internetService.displayAlert();
      return;
    }
    const modal = await this.modalCtrl.create({
      component: ReporteAveriaModalPage,
    });
    await modal.present();
  }
}

