import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment.prod';

@Component({
  selector: 'app-propaganda',
  templateUrl: './propaganda.page.html',
  styleUrls: ['./propaganda.page.scss'],
})
export class PropagandaPage implements OnInit {
  public secureUrl: string = environment.publicidadUrl;
  constructor() { }

  ngOnInit() {
  }

}
