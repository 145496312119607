import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ReporteAveriaModalPageRoutingModule } from './reporte-averia-modal-routing.module';

import { ReporteAveriaModalPage } from './reporte-averia-modal.page';
import { PipesModule } from '../../../pipes/pipes.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    PipesModule,
    ReporteAveriaModalPageRoutingModule
  ],
  declarations: [ReporteAveriaModalPage]
})
export class ReporteAveriaModalPageModule {}
