export let HOTELS = [
  {
    id: 1,
    name: "Quinta San José",
    Ubicacion: "Caracas",
    UrlMaps: "https://www.google.com/maps/place/CableHOGAR/@10.4860394,-66.9438706,17z/data=!4m8!1m2!2m1!1sUrb.+Las+Fuentes+de+El+Para%C3%ADso.+2da+Av.+entre+calle+2+y+3.+Quinta+San+Jos%C3%A9.+El+Para%C3%ADso+-+Caracas!3m4!1s0x8c2a5f0ee5823411:0x579d8dfa22a71e03!8m2!3d10.4859999!4d-66.9431158?hl=es",
    location: { lat: 10.48601, lon: -66.94312 },
    address: ["📌Urb. Las Fuentes de El Paraíso. 2da Av. entre calle 2 y 3. Quinta San José. El Paraíso - Caracas"],
    description: "9:00AM a 4:00PM",
    thumb: "assets/img/Screenshot_12.png",
    images: ["assets/img/Screenshot_12.png"]
  },
  {
    id: 2,
    name: "Sucursales",
    Ubicacion: "Valencia",
    UrlMaps: "https://www.google.com/maps/place/Metr%C3%B3polis+Shopping+Center/@10.1834052,-67.9758901,21z/data=!4m9!1m2!2m1!1sMetropolis:+Shopping,+Nivel+Agua,+Ancla+2,+Subiendo+a+la+Rampla+San+Diego+Carabobo.+Venezuela!3m5!1s0x8e8067ed13da22c7:0xf68543a4eb33e1fa!8m2!3d10.1834313!4d-67.975941!15sCl1NZXRyb3BvbGlzOiBTaG9wcGluZywgTml2ZWwgQWd1YSwgQW5jbGEgMiwgU3ViaWVuZG8gYSBsYSBSYW1wbGEgU2FuIERpZWdvIENhcmFib2JvLiBWZW5lenVlbGFaWiJYbWV0cm9wb2xpcyBzaG9wcGluZyBuaXZlbCBhZ3VhIGFuY2xhIDIgc3ViaWVuZG8gYSBsYSByYW1wbGEgc2FuIGRpZWdvIGNhcmFib2JvIHZlbmV6dWVsYZIBE21lbnNfY2xvdGhpbmdfc3RvcmWaASRDaGREU1VoTk1HOW5TMFZKUTBGblNVUkhNamw1UnpSUlJSQUI",
    location: { lat: 10.183448443304666, lon: -67.97593772558157 },  
    address: [
      "📌C.C.Metropolis: Shopping, Nivel Agua, Ancla 2, Subiendo a la Rampla, Frente al Banesco.San Diego Carabobo.",
      "📌 C.C. Vía Veneto: Av. 168 Salvador Feo La Cruz Este - Oeste, Naguanagua 2005. Local F-5 Nivel Fiorentina.",
      "📌 C.C. Paseo Las Industrias, Nivel Mezzanina, Local, L3-119 Valencia Carabobo",
    ],
    description: "9:00AM a 4:00PM",
    thumb: "assets/img/Screenshot_1.png",
    images: ["assets/img/Screenshot_1.png"]
  },
  {
    id: 3,
    name: "Centro Empresarial Leonardo DaVinci",
    Ubicacion: "Barquisimeto",
    UrlMaps: "https://www.google.com/maps/place/Centro+Comercial+Leonardo+Da+Vinci/@10.0688343,-69.3015365,21z/data=!4m5!3m4!1s0x8e876770c1c7ce8f:0x8308a70fd95497a6!8m2!3d10.0688295!4d-69.3014546",
    location: { lat: 10.0688448564042, lon: -69.30125888631842 }, 
    address: ["📌Carrera 21 con Calle 10, Centro Empresarial Leonardo Da Vinci, Planta Baja Local 8."],
    description: "9:00AM a 4:00PM",
    thumb: "assets/img/barquisimeto.jpg",
    images: ["assets/img/barquisimeto.jpg"]
  },
  {
    id: 4,
    name: "Paseo las Delicias Fibex Telecom",
    Ubicacion: "Maracay",
    UrlMaps: "https://www.google.com/maps/place/Fibex+Telecom/@10.2560148,-67.5917644,21z/data=!4m12!1m6!3m5!1s0x8e803b784b006abd:0x690ba19d5edbc05!2sFibex+Telecom!8m2!3d10.2559676!4d-67.5917222!3m4!1s0x8e803b784b006abd:0x690ba19d5edbc05!8m2!3d10.2559676!4d-67.5917222",
    location: { lat: 10.255980129610444, lon: -67.59171885052572 },
    address: ["📌Urb. Base Aragua, C.C Hotel y Paseo las delicias 2, Nivel Mezzanina, local 2MZ-50"],
    description: "9:00AM a 4:00PM.",
    thumb: "assets/img/Maracay.png",
    images: ["assets/img/Maracay.png"]
  },
  {
    id: 5,
    name: "Centro Comercial Sol Center",
    Ubicacion: "Barinas",
    UrlMaps: "https://www.google.com/maps/place/Centro+Comercial+Sol+Center/@8.6143544,-70.2584003,18z/data=!4m9!1m2!2m1!1sAvenida+Alberto+Arvelo+Torrealba+C.C+Sol+Center+1er+piso+local+%2307!3m5!1s0x8e7b57766e7a33ff:0x5df5a32dba005f16!8m2!3d8.6147777!4d-70.2577969!15sCkJBdmVuaWRhIEFsYmVydG8gQXJ2ZWxvIFRvcnJlYWxiYSBDLkMgU29sIENlbnRlciAxZXIgcGlzbyBsb2NhbCAjMDdaQyJBYXZlbmlkYSBhbGJlcnRvIGFydmVsbyB0b3JyZWFsYmEgY2Mgc29sIGNlbnRlciAxZXIgcGlzbyBsb2NhbCAjMDeSAQ9zaG9wcGluZ19jZW50ZXI",
    location: { lat: 8.614786680514984, lon: -70.25779535902208 },
    address: ["📌Avenida Alberto Arvelo Torrealba C.C Sol Center 1er piso local #07"],
    description: "9:00AM a 4:00PM",
    thumb: "assets/img/Screenshot_2.png",
    images: ["assets/img/Screenshot_2.png"]
  },
  {
    id: 6,
    name: "La Guaira",
    Ubicacion: "La Guaira",
    UrlMaps: "https://www.google.com/maps/place/Centro+Comercial+Empresarial+Puertas+Del+Sol/@10.5952671,-66.9504811,20z/data=!4m9!1m2!2m1!1sCalle+principal+de+maiquetia+centro+comercial+puerta+del+sol+piso+2+oficinas+p-02+y+p-03.!3m5!1s0x8c2a5ce2b2fdf939:0xceeba27cbd74797e!8m2!3d10.5953124!4d-66.9503032!15sCllDYWxsZSBwcmluY2lwYWwgZGUgbWFpcXVldGlhIGNlbnRybyBjb21lcmNpYWwgcHVlcnRhIGRlbCBzb2wgcGlzbyAyIG9maWNpbmFzIHAtMDIgeSBwLTAzLlpaIlhjYWxsZSBwcmluY2lwYWwgZGUgbWFpcXVldGlhIGNlbnRybyBjb21lcmNpYWwgcHVlcnRhIGRlbCBzb2wgcGlzbyAyIG9maWNpbmFzIHAgMDIgeSBwIDAzkgEPc2hvcHBpbmdfY2VudGVy",
    location: { lat: 10.595330493183447, lon: -66.95030809612591 },
    address: ["📌Calle principal de maiquetia centro comercial puerta del sol piso 2 oficinas p-02 y p-03."],
    description: "9:00AM a 4:00PM",
    thumb: "assets/img/la guaira.png",
    images: ["assets/img/la guaira.png"]
  },
  {
    id: 7,
    name: "Lecheria",
    Ubicacion: "Lecheria",
    UrlMaps: "https://www.google.com/maps/place/Centro+Comercial+La+Concha/@10.1829036,-64.6917984,17z/data=!3m1!4b1!4m5!3m4!1s0x8c2d736906a49f47:0xf567afe634e5cb1a!8m2!3d10.1829036!4d-64.6896097",
    location: { lat: 10.182921742449679, lon: -64.68961171443131 },
    address: ["📌Av. Principal de Lecheria,frente al CC La Concha, quinta Antoniella"],
    description: "9:00AM a 4:00PM",
    thumb: "assets/img/lecheria.png",
    images: ["assets/img/lecheria.png"]
  },
  {
    id: 8,
    name: "Puerto la Cruz",
    Ubicacion: "Puerto la Cruz",
    UrlMaps: "https://www.google.com/maps/place/C.C+El+Paseo/@10.2236726,-64.6359192,19z/data=!4m9!1m2!2m1!1sPuerto+la+Cruz,+calle+carabobo,+prolongacion+Paseo+Colon,+Centro.!3m5!1s0x8c2d7505d37db97d:0x560cba0cffd38daa!8m2!3d10.2238315!4d-64.6356372!15sCkFQdWVydG8gbGEgQ3J1eiwgY2FsbGUgY2FyYWJvYm8sIHByb2xvbmdhY2lvbiBQYXNlbyBDb2xvbiwgQ2VudHJvLlo_Ij1wdWVydG8gbGEgY3J1eiBjYWxsZSBjYXJhYm9ibyBwcm9sb25nYWNpb24gcGFzZW8gY29sb24gY2VudHJvkgEPc2hvcHBpbmdfY2VudGVy",
    location: { lat: 10.223841398571793, lon: -64.63563720007686 },
    address: ["📌Puerto la Cruz, calle carabobo, prolongacion Paseo Colon, Centro."],
    description: "9:00AM a 4:00PM",
    thumb: "assets/img/puertoCruz.png",
    images: ["assets/img/puertoCruz.png"]
  },
  /* {
    id: 9,
    name: "Puerto Cabello",
    Ubicacion: "Carabobo",
    UrlMaps: "https://www.google.com/maps/place/C.C+Plaza+City/@10.4649845,-68.0078608,21z/data=!4m8!1m2!2m1!1sAv.+Juan+Jos%C3%A9+Flores,+CC+Plaza+City.Comercial+Fundeso,+Oficina+%237.3.!3m4!1s0x8e8053ce5cf68fcd:0x45bfd01459dce6cb!8m2!3d10.4650257!4d-68.0078011",
    location: { lat: 10.465236707368469, lon: -68.0077876890822 },
    address: ["📌Centro Comercial Vía Veneto Piso Florencia Local F-05 Naguanagua"],
    description: "9:00AM a 4:00PM",
    thumb: "assets/img/Plaza.png",
    images: ["assets/img/Plaza.png"],
  }, */
]



