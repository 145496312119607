import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment' //'../../../environments/environment';
import { LogErrorsService } from './log-errors.service';

@Injectable({
  providedIn: 'root'
})
export class ConsultasService {
  public UrlUpdate: any

  constructor(
    public http: HttpClient,
    private _logs: LogErrorsService,
  ) { }

  UseApp(Datos: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        this.http.post(environment.URLApi + "RegisterUseApp", Datos).subscribe(data => {
          resolve(data)
        }, (error: any) => {
          this._logs.insertarError(error.message);
          reject(error)
        })

      } catch (error) {
        this._logs.insertarError(error.message);
        reject(error)
      }
    })
  }

  ServerDisponibles(Cedula: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        this.http.get(environment.URLApi + `ServerFree/${Cedula}`).subscribe((data: any) => {
          resolve(data)
        }, (error: any) => {
          this._logs.insertarError(error.message);
          reject(error)
        })

      } catch (error) {
        this._logs.insertarError(error.message);
        reject(error)
      }
    })
  }

  VerificacionFibexPlay(Cedula: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        this.http.get(environment.URLApi + `ClientFP/${Cedula}/${environment.Lic}`).subscribe((data: any) => {
          resolve(data)
        }, (error: any) => {
          this._logs.insertarError(error.message);
          reject(error)
        })

      } catch (error) {
        this._logs.insertarError(error.message);
        reject(error)
      }
    })
  }

  GetSaldoCedula(Cedula: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        this.http.get(environment.URLApi + `SaldoCe/${Cedula}/${environment.Lic}`).subscribe(data => {
          const SaldoD = JSON.parse(data[0])

          if (SaldoD.data.info.length > 0) {

            resolve(SaldoD.data.info)
          } else {
            resolve(null)
          }

        }, error => {
          this._logs.insertarError(error.message);
          reject(error)
        });

      } catch (error) {
        this._logs.insertarError(error.message);
        reject(error)
      }
    })
  }

  GetSaldoContrato(NroContrato: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        this.http.get(environment.URLApi + `SaldoCo/${NroContrato}/${environment.Lic}`).subscribe(data => {
          //          resolve(data);
          const DataSaldo = JSON.parse(data[0])

          if (DataSaldo.data.info.length > 0) {
            resolve(DataSaldo.data.info[0])
          } else {
            resolve(null)
          }

        }, error => {
          this._logs.insertarError(error.message);
          reject(error)
        });


      } catch (error) {
        this._logs.insertarError(error.message);
        reject(error)
      }
    })
  }

  InfoContact() {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        const DataQuery = {
          query: `
            query {
              InfoContact(
                token:"${environment.Token}" 
                lic:"${environment.Lic}"
              ){
                lic
                Content
              }
            }`,
        }

        this.http.post(environment.ApiGraphQl, DataQuery).subscribe((response: any) => {

          resolve(response.data.InfoContact)

        }, (error) => {
          this._logs.insertarError(error.message);
          reject(error);
        });


      } catch (error) {
        this._logs.insertarError(error.message);
        console.error(error)
      }
    })
  }

  InfoEquiposClientes(Cedula: any) {
    return new Promise<any[]>(async (resolve: any, reject: any) => {
      try {

        this.http.get(environment.URLApi + `InfoEquipos/${Cedula}/${environment.Lic}`).subscribe(data => {
          const Info = JSON.parse(data[0])
          resolve(Info.data.info)
        }, (error: any) => {
          this._logs.insertarError(error.message);
          reject(error)
        })

      } catch (error) {
        this._logs.insertarError(error.message);
        reject(error)
      }
    })
  }

  GetFacturasCliente(NroContrato: any) {
    return new Promise<any>(async (resolve: any, reject: any) => {
      try {

        this.http.get(environment.URLApi + `FacturaEdoCuentaCo/${NroContrato}/${environment.Lic}`).subscribe(data => {
          const Info = JSON.parse(data[0])
          resolve(Info.data.info)
        }, (error: any) => {
          this._logs.insertarError(error.message);
          reject(error)
        })

      } catch (error) {
        this._logs.insertarError(error.message);
        reject(error)
      }
    })
  }

  TurBoUpgrade(Data: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        let DataAPI: any = {
          "id_det_orden": '626A92B0BCE6B4130441',
          "detalle_orden": `Solicitud de ${Data.Accion} a ${Data.Plan}`,
          "id_contrato": Data.id_contrato,
          "Lic": environment.Lic
        }

        const DataQuery = {
          query: `
          query {
            UpgrateTurboClient(
              token:"${environment.Token}"
              Data:{
                Cedula:"${Data.identidad}"
                idDevice:"${Data.codigo_es}"
                Accion:"${Data.Accion}"
                OldPlan:"${Data.PlanActual}"
                NewPlan:"${Data.Plan}"
                Status:${Data.Status || 0}
                Email:"${Data.Email || ""}"
              })
            }`,
        }

        this.http.post(`${environment.URLApi}CargarServi`, DataAPI).subscribe((Res: any) => { console.log('Respuesta SAE '); console.log(Res) })

        this.http.post(environment.ApiGraphQl, DataQuery).subscribe((response: any) => {

          resolve(response.data.UpgrateTurboClient)

        }, (error) => {
          this._logs.insertarError(error.message);
          reject(error);
        });


      } catch (error) {
        this._logs.insertarError(error.message);
        console.error(error)
      }
    })
  }

  NewProspecto(Data: any, Mensaje: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        /* json ejemplo 
        {
    tipoCliente:"Natural"
    fullName:"juan lobo"
    identidad:"29845112"
    email:"juanlobo11@outlook.com"
    phone:"584143771155"
    ciudad:"Caracas"
    zona:"El silencio"
    direccion:"vsdtghsesgethwsegr"
    latitude:10.4838319
    longitude:-66.853274
  }
         */

        const DataQuery = {
          query: `
          query {
            ClientProspecto(
              token:"${environment.Token}"
              Data:{
                tipoCliente:"${Data.tipoCliente}"
                fullName:"${Data.fullName}"
                identidad:"${Data.identidad}"
                email:"${Data.email}"
                phone:"${Data.phone}"
                ciudad:"${Data.ciudad}"
                zona:"${Data.zona}"
                direccion:"${Data.direccion}"
                latitude:${Data.latitude}
                longitude:${Data.longitude}
              }
            Message:"${Mensaje}")
          }`,
        }

        this.http.post(environment.ApiGraphQl, DataQuery).subscribe((response: any) => {

          resolve(response.data.ClientProspecto)

        }, (error) => {
          this._logs.insertarError(error.message);
          reject(error);
        });


      } catch (error) {
        this._logs.insertarError(error.message);
        reject(error)
      }
    })

  }

  TvPermiso(Data: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        /* ejemplo json
        {
          IdDevice:"bkwerbe"
          Documento:"29.845.112"
          Canal:"TNT"
          Plataforma:"android"
          IP:"192.168.0."
        } 
        */

        const DataQuery = {
          query: `
          mutation{
            SolicitudPermisoTv(
              token:"${environment.Token}"
              Data:{
                IdDevice:"${Data.idDevice}"
                Documento:"${Data.Documento}"
                Canal:"${Data.Canal}"
                Plataforma:"${Data.Plataforma}"
                IP:"${Data.IP || ''}"
              }
            ){
              Permiso
              Publicidad
            }
          }`,
        }

        this.http.post(environment.ApiGraphQl, DataQuery).subscribe((response: any) => {

          resolve(response.data.SolicitudPermisoTv)

        }, (error) => {
          this._logs.insertarError(error.message);
          reject(error);
        });

      } catch (error) {
        this._logs.insertarError(error.message);
        reject(error)
      }
    })
  }

  VersionControl(Cedula: any, version: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        this.http.get(environment.URLApi + `ControlV/${Cedula}/${version}`).subscribe((data: any) => {
          this.UrlUpdate = data.url
          resolve(data)
        }, (error: any) => {
          this._logs.insertarError(error.message);
          reject(error)
        })

      } catch (error) {
        this._logs.insertarError(error.message);
        reject(error)
      }
    })
  }

  GetMenu() {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        this.http.get(environment.URLApi + `MenuApp`).subscribe(data => {
          resolve(data)
        }, (error: any) => {
          this._logs.insertarError(error.message);
          reject(error)
        })

      } catch (error) {
        this._logs.insertarError(error.message);
        reject(error)
      }
    })
  }

  ListService() {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        this.http.get(environment.URLApi + `InfoServices/${environment.Lic}`).subscribe(data => {
          resolve(data)
        }, (error: any) => {
          this._logs.insertarError(error.message);
          reject(error)
        })

      } catch (error) {
        this._logs.insertarError(error.message);
        reject(error)
      }
    })
  }

  GetIpDevice() {

    return new Promise(async (resolve: any, reject: any) => {
      try {

        this.http.get("https://api.ipify.org?format=json").subscribe((data: any) => {
          resolve(data.ip)
        }, (error: any) => {
          this._logs.insertarError(error.message);
          reject(error)
        })

      } catch (error) {
        this._logs.insertarError(error.message);
        reject(error)
      }
    })

  }

  InsertChat(DataChat: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        const DataMutaion = {
          query: `
          mutation{
            CreateChat(
              Chat:{
                idEmpresa:"${DataChat.idEmpresa}"
                phone:"${DataChat.phone}"
                idConversacion:"${DataChat.idConversacion}"
                idmessage:"${DataChat.idmessage}"
                timestamp:${DataChat.timestamp}
                me_to:"${DataChat.me_to}"
                contentType:"${DataChat.contentType}"
                content:"${DataChat.content}"
                TimeRead:"${DataChat.TimeRead}"
                status:${DataChat.status}
                statusc:${DataChat.statusc}
                readmsg:${DataChat.readmsg}
                operador:"${DataChat.operador}"
                lic:"${DataChat.lic}"
                etiqueta:"${DataChat.etiqueta}"
              }
            ){
              idEmpresa
              phone
              idConversacion
              idmessage
              timestamp
              me_to
              contentType
              content
              TimeRead
              status
              statusc
              readmsg
              etiqueta
              operador
              lic
              tag
              DatePc
              priority
            }
          }`,
        }

        this.http.post(environment.ApiGraphQl, DataMutaion).subscribe((response: any) => {

          resolve(response.data.CreateChat)

        }, (error) => {
          this._logs.insertarError(error.message);
          reject(error);
        });


      } catch (error) {
        this._logs.insertarError(error.message);
        reject(error)
      }
    })
  }

  ConsultaChat(Phone: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        const DataQuery = {
          query: `
          query{
            ChatByPhoneApp(
              token:"${environment.Token}" 
              phone:"${Phone}"
              lic:"${environment.LicChat}"
            ){
                idEmpresa
                phone
                idConversacion
                idmessage
                timestamp
                me_to
                contentType
                content
                TimeRead
                status
                statusc
                readmsg
                operador
                lic
                etiqueta
                priority
                DatePc
            }
            }`,
        }

        this.http.post(environment.ApiGraphQl, DataQuery).subscribe((response: any) => {
          resolve(response.data.ChatByPhoneApp || [])

        }, (error) => {
          this._logs.insertarError(error.message);
          reject(error);
        });

      } catch (error) {
        this._logs.insertarError(error.message);
        reject(error)
      }
    })
  }

  SaveDataCLient(DataPost: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        this.http.post(environment.URLApi + `RegsterClienteApp`, DataPost).subscribe((response: any) => {

          resolve(response)

        }, (error) => {
          this._logs.insertarError(error.message);
          reject(error);
        });

      } catch (error) {
        this._logs.insertarError(error.message);
        reject(error)
      }
    })
  }

  GetHistorialOrdenes(IdContrato: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        //CONT764552DAFBB68805
        this.http.get(environment.URLApi + `HistorialOrdenes/${IdContrato}/${environment.Lic}`).subscribe((data: any) => {
          let Historial = JSON.parse(data)
          resolve(Historial.data.info)
        }, (error: any) => {
          this._logs.insertarError(error.message);
          reject(error)
        })

      } catch (error) {
        this._logs.insertarError(error.message);
        reject(error)
      }
    })
  }

  CorreoBienvenida(Email: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        const DataQuery = {
          query: `
          query{
            SendEmail(
              Correo:"${Email}"
              Titulo:"Bienvenido a la App de FIBEX TELECOM"
            ){
          to
            }
          }`,
        }

        this.http.post(environment.ApiGraphQl, DataQuery).subscribe((response: any) => {

          resolve(response.data.SendEmail)

        }, (error) => {
          this._logs.insertarError(error.message);
          reject(error);
        });

      } catch (error) {
        this._logs.insertarError(error.message);
        reject(error)
      }
    })
  }

  sendMail(Email: string, titulo: string, contenido: string) {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        const DataQuery = {
          query: `
          query{
            SendEmail(
              Correo: "${Email}"
              Titulo: "${titulo}"
              Contenido: "${contenido}"
            ){
          to
            }
          }`,
        }

        this.http.post(environment.ApiGraphQl, DataQuery).subscribe((response: any) => {
          resolve(response.data.SendEmail)

        }, (error) => {
          this._logs.insertarError(error.message);
          reject(error);
        });

      } catch (error) {
        this._logs.insertarError(error.message);
        reject(error)
      }
    })
  }

  ConsultaChat_Cola(Phone: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        const DataQuery = {
          query: `
          query{
            ChatCola(
              token:"${environment.Token}"
              Data:{
                phone:"${Phone}"
                lic:"${environment.LicChat}"
                status:0
              }
              Limit:10
            ){
              idEmpresa
              phone
              idConversacion
              idmessage
              timestamp
              contentType    
              content
              TimeRead
              operador
              lic
            }
          }`,
        }

        this.http.post(environment.ApiGraphQl, DataQuery).subscribe((response: any) => {
          resolve(response.data.ChatCola || [])

        }, (error) => {
          this._logs.insertarError(error.message);
          reject(error);
        });

      } catch (error) {
        this._logs.insertarError(error.message);
        reject(error)
      }
    })
  }

  GetServicesClientByIdContrato(IdContrato: String) {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        this.http.get(environment.URLApi + `ServiciosIdCo/${IdContrato}/${environment.Lic}`).subscribe((data: any) => {

          const Datafinal = JSON.parse(data)

          resolve(Datafinal.data.info)

        }, (error: any) => {
          this._logs.insertarError(error.message);
          reject(error)
        })

      } catch (error) {
        reject(error)
      }
    })
  }

  GetAllServiceCliente(Cedula: String) {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        this.http.get(environment.URLApi + `ServiciosCe/${Cedula}/${environment.Lic}`).subscribe((data: any) => {

          const Datafinal = JSON.parse(data)

          resolve(Datafinal.data.info)


        }, (error: any) => {
          this._logs.insertarError(error.message);
          reject(error)
        })

      } catch (error) {
        reject(error)
      }
    })
  }

  GetServicesDisponibles(IdServicio: String, IdContrato: String) {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        this.http.get(environment.URLApi + `ServicesAvailable/${IdServicio}/${IdContrato}/${environment.Lic}`).subscribe(data => {

          resolve(data)

        }, (error: any) => {
          this._logs.insertarError(error.message);
          reject(error)
        })

      } catch (error) {
        reject(error)
      }
    })
  }

}