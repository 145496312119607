import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import Hls from 'hls.js';
import { StremingChannel } from '../../../interfaces/streamingChannel';
import { UserAuthenticationService } from '../../../services/user-authentication.service';
import { ConsultasService } from '../../../services/consultas.service';
import { SocketService } from '../../../services/socket.service';
import { SubscriptionGraphqlService } from "../../../services/subscription-graphql.service";
import gql from "graphql-tag";
import { GuiaProgramacionService } from '../../../services/guia-programacion.service';
import { GetIdDeviceService } from '../../../services/get-id-device.service';
import { EstadisticasService } from '../../../services/estadisticas.service';
import { LogErrorsService } from '../../../services/log-errors.service';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.page.html',
  styleUrls: ['./video-player.page.scss'],
})
export class VideoPlayerPage implements OnInit, OnDestroy {
  @Input() data: StremingChannel;
  public hls: Hls;
  public interval: NodeJS.Timeout;
  private user: any;
  public ipUser: any; //= localStorage.getItem('ipUser');
  public title: string;
  public mute: boolean = false;
  public play: boolean = true;
  public channelGuide: any;
  private video: any = null;
  public error: boolean = false;
  public dayPointer: string = '';
  public daysArr: string[];
  IDDevice:any
  constructor(
    private modal: ModalController,
    private consultService: ConsultasService,
    private userAuthService: UserAuthenticationService,
    private _SubscriptionGraphqlService: SubscriptionGraphqlService,
    private guia : GuiaProgramacionService, 
    private _IdDevice:GetIdDeviceService,
    private _estadisticas: EstadisticasService,
    private _logs: LogErrorsService,
  ) {
    this._estadisticas.registrar(11);
    this.user = this.userAuthService.getUser();
    //
    this.consultService.GetIpDevice().then((ResIP: any) => {
      this.ipUser = ResIP
    })
    
  }

  ngOnInit() {
    // this.socketService.socketConnection('ws://192.168.227.8:8081');
    //this.guia.guideState.subscribe((data) => {
     // this.getGuia(data);
    //})
    this.getGuia();
    this.init();
    this._IdDevice.getUniqueDeviceID().then((ResId:any)=>{
      this.IDDevice = ResId
    })
  } 

  ngOnDestroy(): void {
    this.hls.destroy();
    clearInterval(this.interval);
    
  }

  init() {
    
    let video = document.getElementById('video-hls') as HTMLMediaElement;
      video.requestFullscreen({
        navigationUI: 'auto'
      });
      if (Hls.isSupported()) {       
        this.hls = new Hls()
        this.title = this.data.channel_title;
        this.hls.loadSource(this.data.source);
        this.hls.attachMedia(video);
        this.hls.on(Hls.Events.MEDIA_ATTACHED, function () {
          video.muted = false;
          video.play();
        });
        this.timerUserWatchingStreaming();
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = 'https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8';
        video.addEventListener('canplay', function () {
          video.play();
        });
        this.timerUserWatchingStreaming();
      }
  }

  getGuia() {
    try {
      const dataGuide = this.guia.getData();
      const guide = !!this.data.guide_id ? dataGuide.tv.programme[this.data.guide_id] : null;
      if (!!guide) {
        const dateObj = new Date()
        const month = dateObj.getMonth() + 1; //months from 1-12
        const day = dateObj.getDate();
        const year = dateObj.getFullYear();
        const date = `${month}/${day}/${year}`;
        this.daysArr = Object.keys(guide.programmeItems);
        this.dayPointer = guide.programmeItems[date] ? date : this.daysArr[this.daysArr.length - 1];
      }
      this.channelGuide = guide;
      if (guide) {
        setTimeout(()=> {
          this.setCarrousellPosition();
        }, 300)
      }
      
    } catch (error) {
      this._logs.insertarError(error.message);
      this.channelGuide = null;
    }
   
  }

  selectDay(day:  string) {
    if (this.dayPointer !== day) {
      this.dayPointer = day;
      this.setCarrousellPosition();
      const target = document.querySelector('.grid-items-container');
      target.scrollTop = 0;
    }
  }

  setCarrousellPosition() {
    const container =  document.querySelector('.days-container'); 
    const position = this.daysArr.indexOf(this.dayPointer);
    const target:any = container.children[position];
    if (target) {
      if (position >= 0 || position < this.daysArr.length) {
        target.parentElement.parentElement.scrollLeft = target.offsetLeft + target.clientLeft - 56;
      }
      
    }
  }

  handleNextPosition() {
    const currentPosition =  this.daysArr.indexOf(this.dayPointer);
    if (currentPosition <= this.daysArr.length - 2) {
      this.dayPointer = this.daysArr[currentPosition + 1];
      this.setCarrousellPosition()
    }
  }

  hanldePrevPosition() {
    const currentPosition =  this.daysArr.indexOf(this.dayPointer);
    if (currentPosition >= 1) {
      this.dayPointer = this.daysArr[currentPosition - 1];
      this.setCarrousellPosition()
    }
  }

  hanldeMute() {

    if (this.mute) {
      this.mute = false;
    } else {
      this.mute = true;
    }
    this.video.muted = this.mute;
  }

  hanldePlay() {
    if (this.video.paused || this.video.ended) {
      this.play = true;
      this.video.play();
    } else {
      this.play = false;
      this.video.pause();
    }
    
  }

  closeModal() {
    this.modal.dismiss();
    if( !navigator.userAgent.toLocaleLowerCase().includes('android' || 'iphone' || 'ipad' || 'webos')  ) {
      this.hls.destroy();
    }
    clearInterval(this.interval);
  }

  fancyTimeFormat(duration:number) {   
    // Hours, minutes and seconds
    let hrs = Math.floor(duration / 3600);
    let mins = Math.floor((duration % 3600) / 60);
    let secs = Math.floor(duration % 60);

    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = "";

    if (hrs > 0) {
        ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  }

  formatDate(dateString: string) {
    const date = new Date(dateString);
    const options : any = { weekday: 'long', month: 'long', day: 'numeric' };
    const string = Intl.DateTimeFormat('es-ES', options).format(date);
    return `${string}`.replace('de', '-') ;
  }

  formatMinutesHours(dateString: string) {
    const date = new Date(dateString);
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return `${hours < 10 ? `0${hours}`: hours}:${minutes < 10 ? `0${minutes}`: minutes}`
  }

  timerUserWatchingStreaming() {
    this.interval = setInterval(() => {
      this.consultService.TvPermiso({
        idDevice: this.IDDevice,
        Documento: this.user.identidad,
        Canal: this.data.channel_title,
        Plataforma: "android",
        IP: this.ipUser || ''
      }).then((res) => {
        console.log(res);
        console.log(new Date())
      })
    }, 300000);
  }
}