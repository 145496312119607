import { Injectable } from '@angular/core';
import { CedulaStorage } from './cedula-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ManageCuentaService {
  public current: any = null;
  constructor(
    private _cedulaStorage: CedulaStorage,
  ) {
    if (!this.current) {
      this.getFisrt();
    }
  }

  setCurrent(cuenta: any) {
    this.current = cuenta;
  }

  getFisrt() {
    try {
      this.current = this._cedulaStorage.getCedulas()[0];
      console.log(this._cedulaStorage.getCedulas()[0])
    } catch (error) {
      this.current = null;
    }
  }

}
