import { Injectable } from '@angular/core';
import { UniqueDeviceID } from '@ionic-native/unique-device-id/ngx';
import { AlertController } from '@ionic/angular';
import { LogErrorsService } from './log-errors.service';

@Injectable({
  providedIn: 'root'
})
export class GetIdDeviceService {

  constructor(
    private uniqueDeviceID: UniqueDeviceID,
    public alertController: AlertController,
    private _logs: LogErrorsService,  
  ) { }

  async getUniqueDeviceID() {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        const id = await this.uniqueDeviceID.get();
        resolve(id)

      } catch (error) {
        this._logs.insertarError(error.message);
        console.error(error)

      }
    })
  }
}
