import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { SubscriptionGraphqlService } from '../../../services/subscription-graphql.service';

@Component({
  selector: 'app-notification-modal',
  templateUrl: './notification-modal.page.html',
  styleUrls: ['./notification-modal.page.scss'],
})
export class NotificationModalPage implements OnInit {
  @Input() data: any;
  constructor(
    private modalCtrl: ModalController,
    private _SubscriptionGraphqlService: SubscriptionGraphqlService,
    ) { 
    this._SubscriptionGraphqlService.OpSelect.subscribe((op:any)=>{
      if(op.option==="op0-8") {
        this.closeModal();
      }
    })
  }

  ngOnInit() {
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

}
