import { Injectable, EventEmitter } from '@angular/core';
import { AlertController, NavController, Platform } from '@ionic/angular';
import { Network } from '@awesome-cordova-plugins/network/ngx';

import { BehaviorSubject, fromEvent, merge, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { mapTo } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { LocalStorageService } from './local-storage.service';
import { LogErrorsService } from './log-errors.service';

@Injectable({
  providedIn: 'root'
})
export class VerifyInternetStatusService {
  public onLine: boolean = true;
  public internetState: EventEmitter<any> = new EventEmitter<any>()

  private online: Observable<boolean> = null;
  private hasConnection = new BehaviorSubject(false);
  DataUser: any

  constructor(
    public alertCtrl: AlertController,
    private navCtrl: NavController,
    private network: Network,
    private platform: Platform,
    private http: HttpClient,
    private _localStorageService: LocalStorageService,
    private _logs: LogErrorsService, 
  ) {
    this.onLine = true;

    this.DataUser = this._localStorageService.get('user')

    if (this.platform.is('cordova')) {
      // on Device
      this.network.onConnect().subscribe(() => {
        this.hasConnection.next(true);
        return;
      });
      this.network.onDisconnect().subscribe(() => {
        this.hasConnection.next(false);
        return;
      });
    } else {
      // on Browser
      this.online = merge(
        of(navigator.onLine),
        fromEvent(window, 'online').pipe(mapTo(true)),
        fromEvent(window, 'offline').pipe(mapTo(false))
      );

      this.online.subscribe((isOnline) => {
        if (isOnline) {
          this.hasConnection.next(true);
        } else {
          this.hasConnection.next(false);
        }
      });
    }
    this.testNetworkConnection();

  }

  initService() {
    try {
      window.addEventListener('online', (event) => this.updateOnlineStatus());
      window.addEventListener('offline', (event) => this.updateOnlineStatus());
    } catch (error) {
      this._logs.insertarError(error.message);
    }
  }

  initPhoneNetworkService() {
    this.network.onDisconnect().subscribe(() => {
      this.onLine = false;
      this.internetState.emit(false);
    })
    this.network.onConnect().subscribe(() => {
      this.onLine = true;
      this.internetState.emit(true);
    })
  }

  async updateOnlineStatus() {
    this.onLine = navigator.onLine
    this.internetState.emit(navigator.onLine);
  }

  verifyConection(isOnline: boolean, goBack: boolean = false) {
    if (!isOnline) {
      this.displayAlert();
    }
  }

  async displayAlert() {
    const alert = await this.alertCtrl.create({
      header: 'No tiene internet',
      message: 'Conectese a internet para poder usar las funciones de la Aplicación.',
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
          cssClass: 'secondary',
        }
      ]
    });

    await alert.present();
  }

  public getNetworkType(): string {
    return this.network.type;
  }

  public getNetworkStatus(): Observable<boolean> {
    return this.hasConnection.asObservable();
  }

  private getNetworkTestThomas(): Observable<any> {
    return this.http.get(`${environment.URLApi}MenuApp`);
  }
  private getNetworkTestCRM(): Observable<any> {
    return this.http.get(`${environment.apiVerifyCedula}cedula=${this.DataUser.identidad}`);
  }
  private getNetworkTestIP(): Observable<any> {
    return this.http.get(`https://api.ipify.org?format=json`);
  }

  public async testNetworkConnection() {
    try {

      this.getNetworkTestThomas().subscribe(
        success => {
          this.hasConnection.next(true);
          this.internetState.emit(true);
          return;
        }, error => {

          this.getNetworkTestCRM().subscribe((response: any) => {
            this.hasConnection.next(true);
            this.internetState.emit(true);
            return;
          }, (error) => {
            this.getNetworkTestIP().subscribe((response: any) => {
              this.hasConnection.next(true);
              this.internetState.emit(true);
              return;

            }, (error) => {
              this._logs.insertarError(error.message);
              this.hasConnection.next(false);
              this.internetState.emit(false)
              return;
            });

          });

        });

    } catch (error) {
      this._logs.insertarError(error.message);
      this.hasConnection.next(false);
      return;
    }
  }
}
