import { Component, OnInit } from '@angular/core';

import { ConsultasService } from '../../services/consultas.service';

@Component({
  selector: 'app-new-update',
  templateUrl: './new-update.page.html',
  styleUrls: ['./new-update.page.scss'],
})
export class NewUpdatePage implements OnInit {
  Url: any
  Mensaje = 'Hemos encontrado una nueva actualización disponible, por favor actualizar a la versión más reciente para que puede seguir disfrutando de Fibex Oficina Móvil'

  constructor(
    private ConsultasS: ConsultasService
  ) { }

  ngOnInit() {

    this.Url = this.ConsultasS.UrlUpdate

  }

  GoToUpdate(){
    window.open(this.Url);
  }


}
