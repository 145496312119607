import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ManageCuentaService } from '../../../services/manage-cuenta.service';
import { CedulaStorage } from '../../../services/cedula-storage.service';

@Component({
  selector: 'app-list-cuenta',
  templateUrl: './list-cuenta.page.html',
  styleUrls: ['./list-cuenta.page.scss'],
})
export class ListCuentaPage implements OnInit {
  public cedulas: any[] = [];
  constructor(
    private _modalCtrl: ModalController,
    private _cedulaStorage: CedulaStorage,
    public _cuentas: ManageCuentaService, 

  ) { 
    this.cedulas = this._cedulaStorage.getCedulas();
    console.log(this.cedulas)
  }

  ngOnInit() {
  }

  closeModal() {
    this._modalCtrl.dismiss({currentCuenta: null, changed: false});
  }

  elegirCuenta(cuenta: any) {
    this._cuentas.setCurrent(cuenta);    
    this._modalCtrl.dismiss({currentCuenta: cuenta, changed: true});
  }

}
