import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.prod';
import { ConsultasService } from './consultas.service';
import { LocalStorageService } from './local-storage.service';
import { UserAuthenticationService } from './user-authentication.service';
import { LogErrorsService } from './log-errors.service';

@Injectable({
  providedIn: 'root'
})

export class EstadisticasService {

  constructor(
    private _http: HttpClient,
    private _localStorageService: LocalStorageService,
    private _authentication: UserAuthenticationService,
    private _logs: LogErrorsService, 
  ) {}

  async registrar(id: number) {
    let date: any;
    date = new Date();
    date = date.getUTCFullYear() + '-' +
    ('00' + (date.getUTCMonth()+1)).slice(-2) + '-' +
    ('00' + date.getUTCDate()).slice(-2) + ' ' + 
    ('00' + date.getUTCHours()).slice(-2) + ':' + 
    ('00' + date.getUTCMinutes()).slice(-2) + ':' + 
    ('00' + date.getUTCSeconds()).slice(-2);

    let contrato: string = "";

    const user = this._authentication.getUser();
    const cedulaCliente = this._localStorageService.get("cedulaCliente");
    if (cedulaCliente) {
      contrato = cedulaCliente.NContrato ;
    } else {
      const cedulas = this._localStorageService.get("cedulas");
      if (cedulas && cedulas[0]) {
        contrato = cedulas[0].numContratos[0];
      } else {
        contrato = user.identidad;
      }
    }
   
    const data = this._localStorageService.get("acciones")  || {};
    data[date] = {
      idAccion: id,
      identidad: user.identidad,
      contrato,
      fecha: date,
    }
    
    this._localStorageService.set("acciones", data);
  }

  async crearAccion(body) {
    try {
      const headers = {
        'x-keys-to-add-id': '["id"]',
        'x-keys-of-arrays': '[]',
        'x-relations': 'false',
      };
      const response = await this._http.post(`${environment.apiDbMaps}create-info/tmEstadisticas`, body, { headers }).toPromise();
      return { 
        error: false, 
        data: response
     }
    } catch (error) {
      this._logs.insertarError(error.message);
      return { 
        error: true, 
        data: null
     }
    }
  }

  async crearAcciones() {
    try {
      const data = Object.values(this._localStorageService.get("acciones")) || [];
      if (!data.length) {
        return;
      }
      await Promise.all(data.map((item: any) => this.crearAccion(item)));
      this._localStorageService.remove("acciones");
    } catch (error) {
      this._logs.insertarError(error.message);
    }
  }

}
