import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { LogErrorsService } from './log-errors.service';
@Injectable({
  providedIn: 'root'
})
export class GuiaProgramacionService {  
  public guideState : EventEmitter<any> = new EventEmitter<any>();
  private guide = null;

  constructor(
    private http: HttpClient,
    private _logs: LogErrorsService, 
  ) { }

  getData() {
    return this.guide;
  }

  async loadGuia() {
    try {
      const response = await this.http.get(environment.apiGuia).toPromise()
      this.guide = response;
      this.guideState.emit(response);
    } catch (error) {
      this._logs.insertarError(error.message);
      this.guideState.emit({error: true});
      this.guide = {
        error: true,
      }
    }
  }
}
