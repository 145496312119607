import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { LogErrorsService } from './log-errors.service';

@Injectable({
  providedIn: 'root'
})
export class GetInfoDeviceService {
  private url: string = 'https://checkip.amazonaws.com/';
  constructor(
    private _logs: LogErrorsService, 
  ) { 
  }

  getIpFromUser(): Observable<string> {
    return from(this.getIPFromAmazon())
  }

  getIPFromAmazon() {
    try {
      return fetch("https://checkip.amazonaws.com/").then(res => res.text())
    } catch (error) {
      this._logs.insertarError(error.message);
      return '';
    }
  }

}
