import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class KioskoGuard implements CanActivate {

  constructor(
    private router: Router, 
    ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      const isKioskoApp = environment.kioskoApp;
      console.log(isKioskoApp)
      if (isKioskoApp) {
        this.router.navigate(['/login']);
        return false;
      }
      return true;
  }
  
}
